import { Dialog, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import FailedIcon from "../../../assets/failed.png";
import PropTypes from "prop-types";
import styles from "./Notification.module.css";
import { Box } from "@mui/system";
import Button from "../Button/Button";

export default function CustomNotificationButton({
  onClose,
  open,
  message,
  buttonTitle,
  btnOnClick
}) {
  // Material State
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={
        matches ? styles.notificationDialogMobile : styles.notificationDialog
      }
    >
      <Box
        className={
          matches
            ? styles.notificationContainerMobile
            : styles.notificationContainer
        }
      >
        <img src={FailedIcon} alt="failed" />
        <Typography
          fontSize={24}
          fontWeight={600}
          sx={{ marginBottom: "20px" }}
        >
          Tidak Berhasil!
        </Typography>
        <Typography fontSize={16} fontWeight={400} marginBottom='10px'>
          {message}
        </Typography>
        <Button
          value={buttonTitle}
          width={"80%"}
          height={"45px"}
          background={"#D81922"}
          color={"white"}
          onClick={() => btnOnClick()}
        />
      </Box>
    </Dialog>
  );
}

CustomNotificationButton.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  message: PropTypes.string,
  buttonTitle: PropTypes.string,
  btnOnClick: PropTypes.func
};
