export const forgetPassAction = (payload) => {
    return {
        type : 'FORGET_PASS',
        payload : payload
    }
}

export const forgetPassOtp = (payload) => {
    return {
        type : 'FORGET_PASS_OTP',
        payload : payload
    }
}

export const clearForget = () => {
    return {
        type : 'CLEAR_FORGET',
    }
}