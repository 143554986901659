import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import styles from "./Register.module.css";
import Success from "../../assets/success.png";
import moment from "moment";

export default function RegisterSuccess() {
  // Material State
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={styles.successContainer}>
      <img src={Success} alt={"success"} />
      <div className={styles.success}>
        <Typography fontSize={matches ? 24 : 20} fontWeight={600} color={`#404040`}>
          Berhasil!
        </Typography>
      </div>
      <div className={styles.desc}>
        <Typography
          fontSize={matches ? 18 : 14}
          fontWeight={400}
          color={`#404040`}
          textAlign={`center`}
        >
          Registrasi anda telah berhasil.
        </Typography>
      </div>
      <div>
        <Typography
          fontSize={matches ? 15 : 12}
          fontWeight={200}
          color={`#404040`}
          textAlign={`center`}
        >
          {moment().format("DD-MMMM-YYYY").replaceAll("-", " ")}
          <br />
          {moment().format("HH:mm")}
        </Typography>
      </div>
    </div>
  );
}
