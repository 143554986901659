const INITIAL_STATE = {
    data: {},
  };
  
  const forgetPassReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "FORGET_PASS":
        return {
          ...state,
          data: {
            phone : action.payload
          }
        };
      case "FORGET_PASS_OTP" :
        return {
          ...state,
          data : {
            ...state.data,
            forget_code : action.payload
          }
        }  
      case "CLEAR_FORGET" :
        return {
          ...state,
          data : {}
        }  
      default:
        return state;
    }
  };
  
  export default forgetPassReducer;
  