import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../atomic/Button/Button";
import styles from "./ForgetPassword.module.css";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { forgetPassOtp } from "../../redux/action/forgetPassAction";
import CustomNotification from "../atomic/Alert/CustomNotification";
import OtpInput from "react-otp-input";

export default function ForgetPasswordOTP({ onClose, onSuccess, onError }) {
  // Redux State
  const dispatch = useDispatch();

  // Local State

  const [newOtpData, setNewOtpData] = useState("");
  const [errorNotification, setErrorNotification] = useState(false);

  const handleSubmit = () => {
    let forgetCode = `${newOtpData}`;
    dispatch(forgetPassOtp(forgetCode));
    onSuccess();
    onClose();
  };

  useEffect(() => {
    if (onError) {
      setErrorNotification(true);
    } else {
      setErrorNotification(false);
    }
  }, [onError]);

  const handleChangeNew = (e) => {
    setNewOtpData(e);
  };

  return (
    <div className={styles.container}>
      <div className={styles.otpSection}>
        <CustomNotification
          onClose={() => setErrorNotification(false)}
          open={errorNotification}
          message={"Kode verifikasi salah, coba lagi"}
        />
        <Typography
          className={styles.lupaKataSandi}
          variant="body2"
          fontSize={24}
          fontWeight={600}
          color={"#404040"}
        >
          LUPA KATA SANDI
        </Typography>

        <div className={styles.otpInstruction}>
          Masukan kode yang dikirimkan ke nomor Handphone yang didaftarkan.
        </div>

        <OtpInput
          onChange={handleChangeNew}
          numInputs={6}
          separator={<span></span>}
          value={newOtpData}
          inputStyle={{
            outline: "none",
            border: "none",
            borderBottom: "1px solid red",
            margin: "20px 10px",
            width: "30px",
            height: "30px",
            fontSize: "16px",
          }}
        />

        <div className={styles.btnOtp}>
          <Button
            value={"Kirim"}
            width={`300px`}
            height={`50px`}
            disabled={newOtpData.length < 6}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}

ForgetPasswordOTP.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.bool,
};
