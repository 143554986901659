import React from "react";
import styles from "./ForgetPassword.module.css";
import Success from "../../assets/success.png";
import { Typography } from "@mui/material";
import moment from "moment";

export default function ForgetPasswordSuccess() {
  return (
    <div className={styles.successContainer}>
      <img src={Success} alt={"success"}/>
      <div className={styles.success}>
        <Typography fontSize={20} fontWeight={600} color={`#404040`}>
          Berhasil!
        </Typography>
      </div>
      <div className={styles.desc}>
        <Typography
          fontSize={14}
          fontWeight={400}
          color={`#404040`}
          textAlign={`center`}
        >
          Ubah kata sandi anda
          <br /> telah berhasil.
        </Typography>
      </div>
      <div>
      <Typography
          fontSize={10}
          fontWeight={200}
          color={`#404040`}
          textAlign={`center`}
        >
          { moment().format("DD-MMMM-YYYY").replaceAll('-',' ') }
          <br/>
          { moment().format("HH:mm")}
        </Typography>
      </div>
    </div>
  );
}
