import React from "react";
import styles from "./Register.module.css";

export default function SyaratDanKetentuan() {

  return (
    <div style={{textAlign:'center'}}>
      <h3>SYARAT DAN KETENTUAN TERKAIT DATA</h3>
      <p>
        PT Mitrada Prima Nusantara (“Mitrada”) adalah pengembang berbagai
        aplikasi dengan merek GroMart, Mitra Kami, GroUp dan produk dan layanan
        turunannya (“Produk dan Layanan Mitrada”).
      </p>
      <p>
        Syarat dan Ketentuan Terkait Data (“Syarat dan Ketentuan”) ini mengatur
        penggunaan Data dan informasi yang digunakan, dihasilkan, diproses dalam
        berbagai Produk dan Layanan Mitrada. Sangat penting bagi Anda untuk
        membaca dan memahami ketentuan yang terdapat di bawah ini. Dengan
        menggunakan atau mengakses Produk dan Layanan Mitrada, Pengguna telah
        menyetujui ketentuan yang terdapat di dalam Syarat dan Ketentuan ini,
        sebagaimana akan diperbaharui dalam waktu ke waktu.
      </p>
      <br />
      <h4>1. Definisi</h4>
      <p>
        1.1. “Aplikasi” berarti program-program, cookies, bots, dan/atau kode
        program yang dibuat oleh Mitrada atau pengembang software pihak ketiga
        dan digunakan dalam proses memberikan Produk dan Layanan Mitrada.
      </p>
      <p>
        1.2. “Hari Kerja” berarti hari selain hari Sabtu, hari Minggu, atau hari
        lain dimana bank-bank utama yang berada di Jakarta, Indonesia tidak
        terbuka untuk bisnis.
      </p>
      <p>
        1.3. “Data” berarti informasi, data, teks, perangkat lunak, musik,
        suara, potret, grafik, video, pesan atau bahan lain, baik yang dipasang
        secara umum atau secara pribadi yang dikirimkan dan/atau diterima oleh
        Pengguna.
      </p>
      <p>
        1.4. “Hak atas Kekayaan Intelektual” meliputi hak cipta, paten, merek
        dagang, tanda layanan, hak cipta rancangan (baik terdaftar atau tidak
        terdaftar), hak topografi semi konduktor, rahasia dagang dan semua hak
        milik lain yang serupa.
      </p>
      <p>
        1.5. “Produk dan Layanan Mitrada” adalah produk-produk dan/atau
        layanan-layanan yang disediakan oleh Mitrada yang dapat digunakan oleh
        Anda untuk menyiarkan, menukar dan memproses informasi dan Data yang
        disediakan oleh Pengguna dan/atau Mitrada sehingga Pengguna dapat
        memperoleh manfaat dari Data dan informasi tersebut. Layanan Mitrada
        termasuk juga aplikasi-aplikasi dan platform pertukaran informasi yang
        dijalankan dan dikelola oleh Mitrada.
      </p>
      <p>
        1.6. “Prinsipal” berarti sebagaimana didefinisikan dalam Perjanjian
        Layanan Aplikasi.
      </p>
      <p>
        1.7. “Pengguna” atau “Anda” berarti:
        <ul className={styles.innerListSyaratKetentuan}>
          <li>
            a. Setiap perusahaan, perseroan terbatas, kemitraan, Otoritas
            Pemerintah, usaha patungan, fund, trust, asosiasi, sindikat,
            organisasi, atau entitas atau kelompok orang lain, baik yang
            tergabung atau tidak yang dapat menggunakan Aplikasi dan Produk dan
            Jasa Mitrada lainnya; dan
          </li>
          <li>
            b. setiap orang yang mengakses dan menggunakan Aplikasi dan Produk
            dan Layanan Mitrada.
          </li>
        </ul>
      </p>

      <h4>2. Penggunaan Aplikasi</h4>
      <p>
        2.1. Sebagai syarat untuk menggunakan Aplikasi, setiap Pengguna diminta
        untuk membuat sebuah akun [dan memilih kata sandi dan nama pengguna, dan
        memberikan informasi pendaftaran]. Informasi pendaftaran yang anda
        berikan harus akurat, lengkap dan terkini setiap saat. Kegagalan untuk
        memberikan informasi tersebut akan dianggap merupakan pelanggaran
        terhadap Syarat dan Ketentuan ini, yang dapat mengakibatkan
        ketidakmampuan Pengguna untuk mengakses Aplikasi.
      </p>
      <p>
        2.2. Pengguna bertanggung jawab untuk menjaga kerahasiaan kata sandi
        Pengguna dan bertanggung jawab secara penuh atas semua aktivitas yang
        diakibatkan oleh penggunaan kata sandi dan dilakukan melalui akun
        Pengguna.
      </p>
      <p>
        2.3. Aplikasi memungkinkan Pengguna untuk mengirimkan Data. Pengguna
        sepenuhnya bertanggungjawab terhadap Data yang dikirimkan ke dalam
        Aplikasi.
      </p>
      <p>
        2.4. Mitrada mencadangkan hak untuk mengadakan atau mengubah praktek
        umum dan batasan mengenai pemakaian Aplikasi, Produk dan Layanan Mitrada
        dan NexCloud, termasuk jumlah maksimum hari di mana Data akan disimpan
        dalam setiap bagian khusus pada NexCloud, dan ruang disk maksimum yang
        akan disediakan pada server Mitrada kepada setiap Pengguna.
      </p>
      <h4>3. Data Yang Mitrada Kumpulkan</h4>
      <p>
        3.1. Mitrada diperbolehkan untuk mengumpulkan Data dan informasi yang
        tersedia pada Aplikasi, termasuk namun tidak terbatas sebagai berikut:
        <ul className={styles.innerListSyaratKetentuan}>
          <li>
            a. Data atau informasi yang disedikan kepada Aplikasi oleh Anda
          </li>
          <li>b. Data atau informasi yang diperoleh dari peggunaan Aplikasi</li>
          <li>
            c. Informasi pribadi yang diperlukan untuk masuk kedalam Aplikasi
            kami termasuk nama, alamat e-mail dan informasi lainya yang
            dibutuhkan.
          </li>
          <li>
            d. Infoimasi terkait dengan produk Anda, detail dari konsumen Anda,
            bisnis Anda, aktivitas perdagangan Anda, data keuangan bisnis Anda
            dan data lainya yang Anda masukkan ke dalam Aplikasi kami dan/atau
            diproses oleh Aplikasi kami.
          </li>
          <li>
            e. Informasi dari sumber-sumber lainnya yang Anda berikan kepada
            kami untuk kami gunakan dan proses sebagai bagian dari pemberian
            layanan kami.
          </li>
          <li>
            f. Jaringan dan koneksi Anda yang terhadapnya Anda dan pihak – pihak
            lainnya terkoneksi menggunakan Aplikasi kami. Kami juga mengumpulkan
            informasi sinkronisasi atau impor/ekspor kepada dan dari perangkat
            tersebut.
          </li>
          <li>g. Kata kunci pencarian.</li>
          <li>
            h. Informasi terkait perangkat. Kami mengumpulkan informasi dari
            atau tentang komputer, telepon seluler, atau perangkat lain tempat
            anda menginstal atau mengakses Layanan Mitrada, tergantung pada hak
            akses yang Anda berikan. Kami dapat mengaitkan informasi yang kami
            kumpulkan dari berbagai perangkat Anda, yang membantu kami
            menyediakan Layanan yang konsisten di seluruh perangkat Anda.
            Informasi perangkat yang kami kumpulkan meliputi:
            <p>
              <ul className={styles.innerListSyaratKetentuan}>
                <li>
                  i. Atribut terkait perangkat, seperti sistem operasi, versi
                  perangkat keras, pengaturan perangkat, nama dan jenis
                  perangkat lunak dan file, kekuatan baterai dan sinyal, dan
                  pengenal perangkat.
                </li>
                <li>
                  ii. Lokasi perangkat, termasuk lokasi geografis tertentu,
                  seperti melalui sinyal GPS, Bluetooth, atau WiFi
                </li>
                <li>
                  iii. Sambungan informasi seperti nama operator seluler anda
                  atau ISP anda, jenis browser bahasa dan zona waktu, nomor
                  ponsel dan alamat IP.
                </li>
                <li>
                  iv. informasi terkait perangkat, seperti crash, aktivitas
                  sistem, pengaturan perangkat keras, jenis browser, bahasa
                  browser, tanggal dan waktu permintaan Anda.
                </li>
                <li>v. Cookies</li>
              </ul>
            </p>
          </li>
          <li>
            i. Informasi dari mitra pihak ketiga. Kami dapat memberikan Layanan
            Mitrada melalui infrastruktur jaringan dan koneksi pihak ketiga atau
            aplikasi sehingga dapat menerima informasi tentang aktivitas Anda
            dan tentang Data Anda dari mitra pihak ketiga.
          </li>
        </ul>
      </p>
      <p>
        3.2. Mitrada mempunyai hak untuk melakukan perubahan pada Data, termasuk
        melakukan perkalian, pembagian, penjumlahan, pengurangan, konversi
        format, dan/atau pemetaan terhadap Data yang terdapat di dalam Aplikasi
        hanya untuk tujuan sebagaimana akan disebutkan dalam Pasal 4 dibawah
        ini. Lebih lanjut, Mitrada juga memiliki hak untuk menggunakan Data yang
        terdapat di dalam Aplikasi untuk digunakan dalam analisis komersial
        internalnya sendiri.
      </p>
      <h4>4. Tujuan Pengumpulan Data</h4>
      <p>
        <p>
          4.1. Kegiatan mengunggah, memasukan, menyimpan, mengirim atau menerima
          Data atau Informasi kepada atau melalui Aplikasi kami memberikan
          Mitrada hak untuk menggunakan, menyimpan, memperbanyak, memodifikasi,
          membuat karya derivatif, mendistribusikan, membuat adaptasi data yang
          berkaitan dengan Aplikasi.
        </p>
        <p>
          4.2. Hak yang Anda berikan terkait dengan Syarat dan Ketentuan ini
          adalah termasuk namun tidak terbatas kepada:
          <ul className={styles.innerListSyaratKetentuan}>
            <li>
              a. pengoperasian, promosi, dan meningkatkan Aplikasi kami, dan
              kesempatan untuk mengembangkan versi terbaru
            </li>
            <li>
              b. Menganalisa perilaku konsumen dan menigkatkan Aplikasi dan
              pengiklanan kami
            </li>
            <li>
              c. Kebutuhan internal seperti audit, analisis data, dan riset
              untuk meningkatkan Produk dan Layanan Mitrada.
            </li>
            <li>
              d. Memberikan informasi tambahan yang lebih berguna untuk memahami
              bagian mana dari Aplikasi, Produk dan Layanan Mitrada yang paling
              diminati pada lokasi tertentu dalam jangka waktu tertentu
            </li>
            <li>
              e. mengumpulkan dan menyimpan rincian bagaimana Aplikasi
              digunakan, termasuk kata kunci pencarian. Informasi ini dapat
              digunakan untuk meningkatkan relevansi hasil yang diberikan dalam
              Aplikasi
            </li>
            <li>
              f. melakukan survei untuk memberikan Aplikasi kami, memberikan
              analisis data dan memberikan saran menggunakan Data untuk
              memungkinkan pengelolaan bisnis Anda dan bisnis pihak yang
              terhubung dengan bisnis Anda yang lebih baik. Sebagai tambahan,
              informasi lokasi memungkinkan kami memberikan informasi tambahan
              untuk membantu Anda mengambil keputusan bisnis yang lebih baik
              atau memantau kinerja karyawan mobile Anda dengan lebih baik
            </li>
            <li>
              g. melakukan pengujian terhadap fitur yang sedang dalam
              pengembangan, dan melakukan audit dan aktifitas pemecahan masalah
              dari Aplikasi.
            </li>
            <li>
              h. memverifikasi akun dan aktivitas, dan untuk mempromosikan
              keamanan Aplikasi kami, seperti dengan menyelidiki aktivitas yang
              mencurigakan atau pelanggaran terhadap persyaratan atau kebijakan
              kami dan untuk melindungi akun Anda dengan menggunakan tim
              insinyur, atau sistem otomatis, atau teknologi canggih seperti
              enkripsi dan pembelajaran mesin dan menawarkan alat keamanan yang
              mudah digunakan yang menambahkan lapisan keamanan ekstra ke akun
              Anda.
            </li>
          </ul>
        </p>
      </p>
      <h4>5. Pembagian Data dan Informasi</h4>
      <p>
        <p>
          5.1. Mitrada dapat membagikan Data dan Informasi sebagaimana dimaksud
          dalam Syarat dan Ketentuan ini dengan tata cara yang konsisten dengan
          Syarat dan Ketentuan ini dan bila memungkinkan membatasi penggunaan
          Data dan Informasi tersebut untuk pelaksanaan layanan Mitrada kepada
          pihak-pihak berikut (“Pihak Pengungkap”):
          <ul className={styles.innerListSyaratKetentuan}>
            <li>
              a. Kepada perusahaan pihak ketiga atau pihak yang dipekerjakan
              oleh Mitrada untuk memproses informasi pribadi atas nama kami
              berdasarkan instruksi kami dan sesuai dengan Syarat dan Ketentuan
              ini. Mitrada dapat berbagi informasi terkait dengan pengguna
              termasuk juga informasi yang berasal dari perangkat seperti
              cookies, file log, pengenal perangkat dan data lokasi, dengan
              pihak ketiga yang membantu Mitrada memberikan layanan kepada Anda
              (“Pemberi Layanan”). Pemberi Layanan akan diberi akses ke Data dan
              Informasi yang sewajarnya diperlukan oleh Mitrada untuk
              menyediakan layanannya.
            </li>
            <li>
              b. kepada anak perusahaan yang dimiliki sepenuhnya dan afiliasinya
              termasuk semua pengurus dan pemegang saham perusahaan-perusahaan
              tersebut
            </li>
            <li>
              c. Dalam hal terjadi merger, akuisisi, kepailitan, pembubaran,
              reorganisasi atau transaksi atau proses serupa yang melibatkan
              pengalihan informasi yang dijelaskan dalam Syarat dan Ketentuan
              ini. Mitrada juga dapat berbagi informasi gabungan atau informasi
              non identitas pribadi dengan mitranya, pengiklan atau pihak
              lainnya.
            </li>
            <li>
              d. untuk kebutuhan pemasaran Mitrada data dalam bentuk agregat
              yang tidak secara khusus menyebutkan seseorang atau suatu entitas
              tertentu.
            </li>
            <li>
              e. Kepada institusi yang berwenang manapun ketika dituntut
              berdasarkan hukum atau peraturan lembaga pemerintahan yang berlaku
            </li>
          </ul>
        </p>
      </p>
      <h4>6. Pernyataan dan Jaminan</h4>
      <p>
        <p>
          6.1. Pengguna dengan ini menyetujui dan mengakui bahwa seluruh Data
          yang dimasukkan sebagaimana dimunculkan pada NexCloud (termasuk tetapi
          tidak terbatas kepada informasi terkait jenis, tipe, ukuran,
          pengemasan, warna, harga, diskon, kesediaan produk, dan pengiriman)
          telah diverifikasi sebagai benar dan lengkap pada saat dimunculkan
          pada NexCloud dan Aplikasi lain manapun, dan Pengguna bertanggung
          jawab sepenuhnya terhadap informasi yang tersedia dalam NexCloud dan
          Aplikasi lainnya.
        </p>
        <p>
          6.2. Tidak Ada Batasan: Mitrada atau Pengguna tidak memiliki batasan
          atau kewajiban apapun yang dapat mempengaruhi pelaksanaan kewajibannya
          berdasarkan Syarat dan Ketentuan ini.
        </p>
        <p>
          6.3. Tidak Ada Pelanggaran atau Konflik: Pelaksanaan Orang atas syarat
          dan ketentuan ini dan dokumen-dokumen lain manapun di mana ia
          merupakan pihak, tidak mengakibatkan atau akan mengakibatkan
          pelanggaran terhadap Hukum yang berlaku.
        </p>
      </p>
      <h4>7. Tidak ada Garansi dan Batasan Tanggung Jawab</h4>
      <p>
        <p>
          7.1. Mitrada menyediakan Aplikasi dan layanannya “sebagaimana adanya”
          dan “sebagaimana tersedia” tanpa jaminan apapun. Tanpa pembatasan ini,
          Mitrada dengan tegas menolak semua jaminan, baik tersurat, tersirat
          atau sesuai undang-undang, mengenai Aplikasi termasuk namun tidak
          terbatas pada jaminan dapat diperjualbelikan, layak untuk tujuan
          tertentu, hak, keamanan, keakuratan dan tidak akan dituntut. Mitrada
          tidak menjamin bahwa Aplikasi akan memenuhi kebutuhan Anda; bahwa
          Aplikasi tidak akan terganggu, aman atau bebas dari kesalahan; bahwa
          informasi yang disediakan melalui Aplikasi adalah akurat, dapat
          diandalkan atau benar; bahwa setiap cacat atau kesalahan akan
          diperbaiki; bahwa Aplikasi akan selalu dapat diakses pada setiap waktu
          dan ditempat manapun; atau bahwa Aplikasi tersebut bebas dari virus
          maupun kompenen berbahaya. Anda mengambil tanggung jawab penuh dan
          risiko kerugian yang muncul dari mengunggah, mengunduh dan/atau
          menggunakan Data atau materi lain yang diperoleh dari Aplikasi.
        </p>
        <p>
          7.2. Mitrada mencadangkan hak untuk menangguhkan atau menghentikan
          akses Pengguna ke Aplikasi dengan pemberitahuan kepada Pengguna jika:
          <ul className={styles.innerListSyaratKetentuan}>
            <li>
              a. Pengguna melanggar ketentuan yang terdapat dalam Syarat dan
              Ketentuan ini,
            </li>
            <li>
              b. Pengguna menggunakan Aplikasi dan/atau Layanan dengan cara yang
              dapat menyebabkan risiko kerugian atau kerugian yang nyata bagi
              Mitrada atau Pengguna lainnya.
            </li>
          </ul>
        </p>
      </p>
      <h4>8. Hak Kekayaan Intelektual</h4>
      <p>
        <p>
          8.1. Mitrada akan mempertahankan atau menyelesaikan dengan
          pengeluarannya sendiri setiap tuntutan terhadap Pengguna serta setuju
          untuk memberikan ganti rugi dan membebaskan Pengguna dari setiap
          kerugian atau kerusakan yang timbul dari setiap gugatan terhadap
          Pengguna yaitu bahwa perangkat keras Mitrada, perangkat lunak Mitrada
          atau setiap peralatan layanan, proses layanan atau Material yang
          dikembangkan sendiri oleh Mitrada dan digunakan oleh Mitrada untuk
          memberikan layanan, melanggar setiap Hak Kekayaan Intelektual pihak
          ketiga (“Gugatan Pelanggaran”) dengan ketentuan Pengguna segera
          memberikan pemberitahuan secara tertulis atas tuntutan tersebut kepada
          Mitrada dan bekerjasama dengan Mitrada dalam, dan memberikan Mitrada
          kewenangan tunggal untuk mengatur pembelaan dan setiap penyelesaian
          terkait. Mitrada harus membayar setiap kerugian dan biaya yang
          akhirnya diputuskan oleh pengadilan dalam setiap Gugatan Pelanggaran
          terhadap Pengguna.
        </p>
        <p>
          8.2. Nama, merek dagang, merek layanan, gambar dan logo Aplikasi yang
          digunakan sehubungan dengan Aplikasi dan Layanan yang diberikan oleh
          Mitrada merupakan merek dagang atau merek dagang terdaftar dari
          Mitrada. Pengguna tidak diberikan hak atau lisensi apapun sehubungan
          dengan merek dagang tersebut di atas.
        </p>
      </p>
      <h4>9. Ketentuan lain-lain</h4>
      <p>
        <p>
          9.1. Hukum Yang Mengatur dan Sengketa: Syarat dan Ketentuan ini ini
          diatur dengan hukum negara Republik Indonesia. Setiap tindakan hukum
          apapun atau sengketa yang mungkin timbul dari, berhubungan dengan,
          atau dalam cara apapun berhubungan dengan penggunaan Aplikasi akan
          diselesaikan di Badan Arbitrase Nasional Indonesia yang berkantor di
          Wahana Graha Lt. 1&2, Jalan Mampang Prapatan No. 2, Duren Tiga,
          Pancoran, RT.4/RW.1, Duren Tiga, Pancoran, Kota Jakarta Selatan,
          Daerah Khusus Ibukota Jakarta 12760 (BANI)
        </p>
        <p>
          9.2. Pembaharuan Aplikasi. Ketika salah satu Aplikasi memerlukan atau
          menyertakan perangkat lunak yang dapat diunduh, perangkat lunak
          tersebut dapat diperbaharui secara sendirinya ketika terdapat versi
          baru dari perangkat lunak tersebut atau fitur baru dari perangkat
          lunak tersebut telah tersedia. Beberapa Aplikasi mungkin mengizinkan
          penyesuaian dari pengaturan pembaruan otomatis.
        </p>
        <p>
          9.3. Perubahan atas Persyaratan. Mitrada berhak, atas kebijakannya
          sendiri untuk mengubah atau mengganti ketentuan yang terdapat dalam
          Syarat dan Ketentuan setiap saat. Anda bertanggung jawab untuk
          meninjau dan mengetahui perubahan-perubahan tersebut. Penggunaan salah
          satu Aplikasi merupakan penerimaan atas perubahan Syarat dan Ketentuan
          yang telah diubah tersebut.
        </p>
        <p>
          9.4. Bahasa: Syarat dan Ketentuan ini dibuat dengan Bahasa Indonesia
          dan Inggris. Kedua versi tersebut memiliki kekuatan hukum yang sama
          dan mengikat para pihak dan merupakan suatu kesatuan. Apabila terdapat
          perbedaan antara kedua versi tersebut, maka versi bahasa Indonesia
          yang akan berlaku.
        </p>
      </p>
      <br/>
    </div>
  );
}
