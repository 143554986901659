import { Dialog, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import SuccessIcon from "../../../assets/success.png";
import PropTypes from "prop-types";
import styles from "./Notification.module.css";
import { Box } from "@mui/system";
import moment from "moment";

export default function CustomSuccessNotification({ onClose, open, message }) {
   // Material State
   const theme = useTheme();
   const matches = useMediaQuery(theme.breakpoints.down("sm")); 
 
  return (
    <Dialog open={open} onClose={onClose} className={matches ? styles.notificationDialogMobile : styles.notificationDialog}>
      <Box className={matches ? styles.notificationContainerMobile : styles.notificationContainer}>
        <img src={SuccessIcon} alt="failed" />
        <Typography fontSize={22} fontWeight={600} sx={{ marginBottom: "20px"}}>Berhasil!</Typography>
        <Typography fontSize={16} fontWeight={400} >{message}</Typography>
        <Box height={'20px'}></Box>
        <Typography fontSize={12} marginTop='20px'>
            {moment().format('DD MMMM YYYY')}
        </Typography>
        <Typography fontSize={12}>
            {moment().format('hh:mm')}
        </Typography>
      </Box>
    </Dialog>
  );
}

CustomSuccessNotification.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  message: PropTypes.string,
};
