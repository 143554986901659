export const registerUser = (payload) => {
    return {
        type : 'REGISTER_USER',
        payload : payload
    }
}

export const clearRegister = () => {
    return {
        type : 'CLEAR_REGISTER',
    }
}