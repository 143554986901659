import React from "react";
import styles from "./Login.module.css";
import userIcon from "../../assets/iconuser.png";
import { Typography } from "@mui/material";
import Button from "../atomic/Button/Button";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export default function LoginSuccess({
  onClose,
  successURL,
  resource,
  onSubmit,
}) {
  const userData = useSelector((state) => state.userData.data);
  return (
    <div className={styles.loginSuccessContainer}>
      <Typography fontSize={24} fontWeight={600} sx={{ color: " #404040" }}>
        Login with GroChat Account
      </Typography>
      <div className={styles.mobileIconContainer}>
        <div className={styles.mobileIconLoginSuccess}>
          <img src={userIcon} alt="user-grochat" />
        </div>
        <Typography fontSize={24} fontWeight={600} sx={{ color: " #404040" }}>
          {userData ? userData.name : ""}
        </Typography>
      </div>
      <p>{resource} will have access to your profile</p>
      <div className={styles.mobileBtnContainer}>
        <a
          target="_blank"
          rel="noreferrer"
          href={successURL}
          style={{ width: "100%", textAlign: "center" }}
        >
          <Button
            value={`Lanjut sebagai ${
              userData &&
              (userData.name?.includes(" ")
                ? userData.name?.substr(0, userData.name?.indexOf(" "))
                : userData.name)
            }`}
            width={"90%"}
            height={`50px`}
            onClick={() => onSubmit()}
          />
        </a>
        <Button
          value={"Kembali"}
          width={"90%"}
          height={`50px`}
          background={"white"}
          color={"#D81922"}
          border={"1px solid #D81922 "}
          onClick={() => onClose()}
        />
      </div>
    </div>
  );
}

LoginSuccess.propTypes = {
  onClose: PropTypes.func,
  successURL: PropTypes.string,
  resource: PropTypes.string,
  onSubmit: PropTypes.func,
};
