export const REGISTER_CONST = {
    // TITLE
    REGISTER_TITLE_MR : 2,
    REGISTER_TITLE_MRS : 3,
    REGISTER_TITLE_MS : 4,

    // PLACEHOLDER
    PLC_FIRST_NAME : "",
    PLC_LAST_NAME : "",
    PLC_PHONE_IDN : "e.g: +628100008008",
    PLC_PHONE_NUMBER : "Nomor Handphone",
    PLC_USERNAME : "",
    PLC_PASSWORD : "Masukan kata sandi",
    PLC_CONFIRM_PASSWORD : "Masukan kembali kata sandi",
    
    // Label
    LBL_PRIA : "Pria",
    LBL_WANITA : "Wanita",

    // Valie
    VL_PRIA : 1,
    VL_WANITA : 2,

    // Device
    WEB_DEVICE : "webDevice"
}