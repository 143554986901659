import React from "react";
import styles from "../Input/InputText.module.css";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";

export default function InputText(props) {
  const {
    name = "",
    onChange,
    placeholder = "",
    label = "",
    error = { noData: false },
    type = "text",
    length,
    required = false,
    width = "90%",
    value = "",
  } = props;

  return (
    <div className={styles.inputContainer}>
      <TextField
        sx={{ width: `${width}` }}
        type={type}
        required={required}
        name={name}
        label={label}
        placeholder={placeholder}
        inputProps={{maxLength : length}}
        onChange={onChange}
        variant="standard"
        size="small"
        value={value ? value : ""}
        InputLabelProps={{ shrink: true }}
      />
      {error && error.capital && (
        <i className={styles.inputWarning}>
          {`*Nama depan tidak valid`}
        </i>
      )}
      {error && error.phoneRegex && (
        <i className={styles.inputWarning}>{`*Format No telepon salah (+62 atau 08)`}</i>
      )}
      {error && error.length && (
        <i className={styles.inputWarning}>
          {`*Panjang maximal ${placeholder} 15 karakter`}
        </i>
      )}
      {error && error.passwordRegex && (
        <i className={styles.inputWarning}>
          {`*Kata sandi harus terdiri dari 8 karakter, 1 huruf kapital, 1 angka, 1 karakter spesial`}
        </i>
      )}
      {error && error.passwordConfirm && (
        <i className={styles.inputWarning}>{`*Password tidak sama`}</i>
      )}
      {error && error.usernameRegex && (
        <i className={styles.inputWarning}>
          {`*Username harus terdiri dari 8 karakter dan 1 angka, tidak ada huruf kapital`}
        </i>
      )}
      {
        error && error.phoneValidate && (
          <i className={styles.inputWarning}>
          {`user tidak dikenal`}
        </i>
        )
      }
    </div>
  );
}

InputText.propTypes = {
  name: PropTypes.string,
  length: PropTypes.number,
  placeholder: PropTypes.string,
  error: PropTypes.object,
  onChange: PropTypes.func,
  type: PropTypes.string,
  required: PropTypes.bool,
  width: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
};
