import {
  Dialog,
  FormControl,
  Input,
  InputLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import { checkUserProfiling } from "../../helper/checkUserProfiling";
import { addToken } from "../../redux/action/validateToken";
import { getNoRestrict, postData } from "../../services/Services";
import CustomNotification from "../atomic/Alert/CustomNotification";
import Button from "../atomic/Button/Button";
import Select from "../atomic/Input/Select";
import OTPMigrations from "../OTP/OTPMigrations";
import { REGISTER_FORM_NAME } from "../Register/registerFormName";
import { REGISTER_TITLE_DATA } from "../Register/registerTitleData";
import { css } from "@emotion/react";
import CustomSuccessNotification from "../atomic/Alert/CustomSuccessNotification";
import CustomNotificationButton from "../atomic/Alert/CustomNotificationButton";

const Migrations = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const userName = searchParams.get("userName");
  const resource_name = searchParams.get("resource_name");
  const resource_id = searchParams.get("resource_id");
  const auth_user_id = searchParams.get("auth_user_id");
  const successURL = searchParams.get("success");
  const userProfiling = searchParams.get("user_profiling");
  // const phone = searchParams.get("phone");
  // const successURL = searchParams.get("success");

  // Material State
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [isValidated, setIsValidated] = useState(false);
  const [openOtp, setOpenOtp] = useState(false);
  const [formData, setFormData] = useState({
    gender: 1,
    title: "",
    firstName: "",
    lastName: "",
    phone: "",
    userName: userName,
  });
  const [isError, setIsError] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [successMigrate, setSuccessMigrate] = useState(false);
  const [migrateError, setMigrateError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (formData.gender !== 0) {
      let migrationData = {
        ...formData,
        gender:
          parseInt(formData.title) === 3 || parseInt(formData.title) === 4
            ? 2
            : 1,
        title: parseInt(formData.title),
        userProfiling:
          resource_id === "care"
            ? userProfiling
            : checkUserProfiling(resource_id),
        authUserID: parseInt(auth_user_id),
      };
      try {
        const result = await postData(
          "/v3/auth/registration/migrate/resource",
          token,
          migrationData
        );
        if (result.data.status === 1) {
          setOpenOtp(true);
        } else if (result.data.status === 1000048) {
          setMigrateError(true);
        } else {
          setOpenErrorAlert(true);
          setErrorMessage(result.data.note);
        }
        setLoading(false);
      } catch (err) {
        if (err) {
          setOpenErrorAlert(true);
          setErrorMessage(err.response);
        }
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "firstName" || e.target.name === "lastName") {
      if (e.target.value.length <= 50) {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    if (!isValidated) {
      dispatch(addToken(token));
      getNoRestrict("/v3/auth/token/internal/verify", token)
        .then((res) => {
          if (res.data.status === 1) {
            setIsValidated(true);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  const handleSuccessOtp = () => {
    setSuccessMigrate(true);
    setOpenOtp(false);
    setFormData({
      firstName: "",
      gender: 0,
      lastName: "",
      phone: "",
      title: formData.title,
      userName: userName,
    });
    setTimeout(
      () => window.location.replace(`${successURL}?&auth_code=${auth_user_id}`),
      5000
    );
  };

  useEffect(() => {
    if (
      !checkFirstName(formData.firstName) ||
      !checkFirstName(formData.lastName) ||
      !/^(62|\+62|0)8[1-9][0-9]{6,16}$/.test(formData.phone) ||
      formData.title === ""
    ) {
      setIsError(true);
    } else if (
      checkFirstName(formData.firstName) &&
      checkFirstName(formData.lastName) &&
      /^(62|\+62|0)8[1-9][0-9]{6,16}$/.test(formData.phone) &&
      formData.title !== ""
    ) {
      setIsError(false);
    }
  }, [formData.firstName, formData.lastName, formData.phone, formData.title]);

  const checkFirstName = (string) => {
    if (!/^[A-Z][a-z]+(([ ][A-Z][a-z])?[a-z]*)*$/.test(string)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {isValidated ? (
        <>
          {
            <Dialog open={openOtp} onClose={() => setOpenOtp(false)}>
              <Box>
                <OTPMigrations
                  onSucces={handleSuccessOtp}
                  userID={auth_user_id}
                  phone={formData.phone}
                />
              </Box>
            </Dialog>
          }
          <CustomNotification
            onClose={() => setOpenErrorAlert(false)}
            open={openErrorAlert}
            message={errorMessage}
          />

          <CustomNotificationButton
            onClose={() => setMigrateError(false)}
            open={migrateError}
            message={
              "Akun ini sudah pernah di migrasi. Silahkan login dengan akun yang sama"
            }
            btnOnClick={() =>
              window.location.replace(
                `${successURL}?&auth_code=${auth_user_id}`
              )
            }
            buttonTitle={"Kembali ke halaman login"}
          />

          <CustomSuccessNotification
            message={
              "Akun Grochat Anda telah berhasil diaktivasi. Silahkan login kembali untuk mulai menggunakan Grochat"
            }
            onClose={() =>
              window.location.replace(
                `${successURL}?&auth_code=${auth_user_id}`
              )
            }
            open={successMigrate}
          />

          <Typography fontWeight={600} style={{ wordSpacing: "2px" }}>
            Migrasi Akun {resource_name} ke Grochat
          </Typography>
          <form onSubmit={handleSubmit} style={{ width: "90%" }}>
            <div style={{ marginBottom: "10px" }}>
              <Select
                name={REGISTER_FORM_NAME.TITLE}
                optionData={REGISTER_TITLE_DATA}
                onChange={handleChange}
                label={"Select Title"}
                value={formData.title}
                defaultvalue={""}
              />
            </div>
            <FormControl
              sx={{
                width: matches ? "100%" : "100%",
                marginBottom: "10px",
              }}
              variant="standard"
            >
              <InputLabel htmlFor="standard-adornment-password" shrink>
                Nama Depan
              </InputLabel>
              <Input
                id="standard-adornment-password"
                onChange={handleChange}
                name={"firstName"}
                length={50}
                required={true}
                value={formData.firstName}
              />
              <Box
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {formData.firstName.length !== 0 &&
                  !checkFirstName(formData.firstName) && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <span
                        style={{
                          color: "red",
                          fontSize: "11px",
                          fontStyle: "italic",
                          textAlign: "left",
                        }}
                      >
                        *Nama depan minimal 2 karakter, hanya terdiri dari huruf
                        alfabet
                      </span>
                      <span
                        style={{
                          color: "red",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        *Setiap kata nama depan diawali dengan huruf kapital
                      </span>
                    </div>
                  )}
              </Box>
            </FormControl>
            <FormControl
              sx={{ width: matches ? "100%" : "100%", marginBottom: "10px" }}
              variant="standard"
            >
              <InputLabel htmlFor="standard-adornment-password" shrink>
                Nama Belakang
              </InputLabel>
              <Input
                id="standard-adornment-password"
                onChange={handleChange}
                name={"lastName"}
                length={50}
                required={true}
                value={formData.lastName}
              />
              <Box
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {formData.lastName.length !== 0 &&
                  !checkFirstName(formData.lastName) && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <span
                        style={{
                          color: "red",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        *Nama belakang minimal 2 karakter, hanya terdiri dari
                        huruf alfabet
                      </span>
                      <span
                        style={{
                          color: "red",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        *Setiap kata nama belakang diawali dengan huruf kapital
                      </span>
                    </div>
                  )}
              </Box>
            </FormControl>
            <FormControl
              sx={{ width: matches ? "100%" : "100%", marginBottom: "10px" }}
              variant="standard"
            >
              <InputLabel htmlFor="standard-adornment-password" shrink>
                Nomor Handphone
              </InputLabel>
              <Input
                id="standard-adornment-password"
                onChange={handleChange}
                name={"phone"}
                inputProps={{
                  maxLength:
                    formData.phone.charAt(0) === "0"
                      ? 12
                      : formData.phone.charAt(0) === "+"
                      ? 14
                      : "",
                }}
                value={formData.phone}
                required={true}
                placeholder={"e.g: +628100008008"}
              />
              <Box
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {formData.phone.length !== 0 &&
                  !/^(62|\+62|0)8[1-9][0-9]{6,16}$/.test(formData.phone) && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "12px",
                        fontStyle: "italic",
                      }}
                    >
                      *Nomor handphone tidak sesuai format
                    </span>
                  )}
              </Box>
            </FormControl>
            <FormControl
              sx={{ width: matches ? "100%" : "100%", marginBottom: "10px" }}
              variant="standard"
            >
              <InputLabel htmlFor="standard-adornment-password" shrink>
                Username
              </InputLabel>
              <Input
                // disabled={true}
                inputProps={{ readOnly: true }}
                id="standard-adornment-password"
                onChange={handleChange}
                name={"userName"}
                value={formData.userName}
              />
            </FormControl>

            <Box
              sx={{ display: "flex", alignItems: "center", height: "10px" }}
            ></Box>
            <Button
              disabled={isError || loading}
              value={"SUBMIT"}
              width={"80%"}
              height={"50px"}
              background={"#D81922"}
              color={"white"}
            />
          </form>
        </>
      ) : loading ? (
        <PuffLoader css={css} color={"black"} loading={loading} size={150} />
      ) : (
        <Typography fontWeight={600} height={"70%"}>
          Akses tidak di izinkan
        </Typography>
      )}
    </>
  );
};

export default Migrations;
