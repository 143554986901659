import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function getNoRestrict(target, data) {
  let head = {
    headers: {
      Authorization: data,
    },
  };
  const url = API_URL + target;
  return await axios.get(url, head);
}

export async function postNoRestrict(target, data) {
  const url = API_URL + target;
  return await axios.post(url, data);
}

export async function postLogin(target, data) {
  const url = API_URL + target;
  const head = {
    headers: {
      "x-device": "web",
    },
  };
  return await axios.post(url, data, head);
}

export async function postData(target, token, data) {
  let head = {
    headers: {
      Authorization: `${token}`,
    },
  };
  const url = API_URL + target;
  return await axios.post(url, data, head);
}

export async function postExternal(target, token, data) {
  let head = {
    headers: {
      Authorization: `${token}`,
    },
  };
  return await axios.post(target, data, head);
}

export async function postDelete(target, token, data) {
  let head = {
    headers: {
      Authorization: `${token}`,
      "x-device": "web",
    },
  };
  const url = API_URL + target;
  return await axios.post(url, data, head);
}

export async function postDataDelete(target, token) {
  let head = {
    headers: {
      Authorization: `${token}`,
    },
  };
  const url = API_URL + target;
  return await axios.delete(url, head);
}
