import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsLogo } from "../../redux/action/homepageAction";
import SuccessNew from "../../assets/success-new.svg";
import Warning from "../../assets/warning-red.svg";
import Button from "../atomic/Button/Button";
import { getNoRestrict } from "../../services/Services";
import { useSearchParams } from "react-router-dom";

const EmailVerificationSuccess = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const code = searchParams.get("activation_code");
  const email = searchParams.get("email");
  const auth_user_id = searchParams.get("user_id");

  const [status, setStatus] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    dispatch(setIsLogo(false));
    verification();
  }, []);

  const verification = async () => {
    try {
      const data = await getNoRestrict(
        `/v5/auth/registration/activation/email/${code}/${email}/${auth_user_id}`,
        ""
      );
      if (data.data.status === 1) {
        setMessage("Email berhasil diverifikasi");
        setStatus(true);
      }
      if (data.data.status === 300009) {
        setMessage(data.data.note.activation);
      }
      if (data.data.status === 300001) {
        setMessage(
          "Link verifikasi tidak valid. Mohon lakukan permintaan ulang link verifikasi yang baru."
        );
      }
    } catch (err) {
      setStatus(false);
      setMessage("Terjadi kesalahan! Mohon hubungi customer service kami");
    }
  };

  return (
    <Box
      display={"flex"}
      flexDirection="column"
      alignItems={"center"}
      justifyContent="center"
      gap={"30px"}
    >
      <img
        src={status ? SuccessNew : Warning}
        alt="status-icon"
        style={{ width: "75px", height: "75px" }}
      />
      <Typography
        fontWeight={status ? 700 : 400}
        fontSize={status ? "20px" : "14px"}
        width={status ? "100%" : "70%"}
      >
        {message}
      </Typography>
      {/* Masih hold sampai QC oke */}
      {/* <Button
        value={"OK"}
        fontWeight={700}
        style={
          status
            ? { padding: "5px 40px", height: "40px" }
            : {
                background: "white",
                border: "1px solid #CF0000",
                color: "#CF0000",
                padding: "5px 40px",
                height: "40px",
              }
        }
      /> */}
    </Box>
  );
};

export default EmailVerificationSuccess;
