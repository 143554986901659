import React from "react";
import styles from "./Error.module.css";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import FailedIcon from "../../assets/failed.png";

export default function ErrorFallback({ error }) {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Box
          display={"flex"}
          flexDirection="column"
          alignItems={"center"}
          justifyContent="center"
          gap={"20px"}
          paddingBottom='100px'
        >
          <img src={FailedIcon} style={{ width: "150px" }} />
          <Typography fontSize={"23px"} fontWeight="600" color="gray">
            {"System Error!"}
          </Typography>
          <Typography
            fontSize={"14px"}
            fontWeight="400"
            maxWidth={"250px"}
            textAlign="center"
          >
            {error.message}
          </Typography>
        </Box>
      </div>
    </div>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.string,
  resetErrorBoundary: PropTypes.func,
};
