import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import { useState } from "react";
import { INPUT_TYPE } from "../../constant/inputTypeConst";
import { REGISTER_CONST } from "../../constant/registerConst";
import InputText from "../atomic/Input/InputText";
import Select from "../atomic/Input/Select";
import { REGISTER_FORM_NAME } from "../Register/registerFormName";
import { REGISTER_TITLE_DATA } from "../Register/registerTitleData";
import Button from "../atomic/Button/Button";
import SyaratDanKetentuan from "../Register/SyaratDanKetentuan";
import CustomModal from "../atomic/Modal/CustomModal";
import OTPRegister from "../OTP/OTPRegister";
import { postLogin } from "../../services/Services";
import { useDispatch } from "react-redux";
import CustomConfirmation from "../atomic/Alert/CustomConfirmation";
import { registerUser } from "../../redux/action/registerAction";
import PropTypes from "prop-types";

const NewRegister = ({ onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const [checkedSyarat, setCheckedSyarat] = useState(false);
  const [openSyarat, setOpenSyarat] = useState(false);
  const [openOtp, setOpenOtp] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(null);

  const [inputRegister, setInputRegister] = useState({
    title: "",
    firstName: "",
    lastName: "",
    phone: "",
    userName: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "phone") {
      if (/^[0-9+]*$/.test(e.target.value)) {
        setInputRegister({
          ...inputRegister,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setInputRegister({
        ...inputRegister,
        [e.target.name]: e.target.value,
      });
    }
  };

  const checkFirstName = (string) => {
    if (!/^[A-Z][a-z]+(([ ][A-Z][a-z])?[a-z]*)*$/.test(string)) {
      return false;
    } else {
      return true;
    }
  };

  const submitService = async () => {
    const newValue = {
      ...inputRegister,
      gender:
        parseInt(inputRegister.title) === 3 ||
        parseInt(inputRegister.title) === 4
          ? 2
          : 1,
      firstName: inputRegister.firstName.trim(),
      lastName: inputRegister.lastName.trim(),
      name: `${inputRegister.firstName.trim()} ${inputRegister.lastName.trim()}`,
      invitationCode: "",
    };
    try {
      const value = await postLogin("/v3/auth/registration/", newValue);
      if (value.data.status === 1) {
        let saveData = {
          ...value.data.data.userModel,
          user_id: value.data.data.userModel.auth.user_id,
        };
        dispatch(registerUser(saveData));
        setOpenOtp(true);
        setOpenConfirmation(false);
      } else if (
        value.data.status === 300010 ||
        value.data.status === 1000045 ||
        value.data.status === 1000048 ||
        value.data.status === 1000049
      ) {
        setOpen(true);
        setStatus("error");
        setMessage(value.data.note);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err) {
        setOpen(true);
        setStatus("error");
        setMessage("Terjadi kesalahan mohon kontak developer Anda.");
        setTitle("System Error!");
        setOpenConfirmation(false);
      }
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    submitService();
  };

  const handleConfirmation = (e) => {
    e.preventDefault();
    setOpenConfirmation(true);
  };

  return (
    <>
      <CustomConfirmation
        onClose={() => setOpenConfirmation(false)}
        open={openConfirmation}
        onConfirm={handleSubmit}
        disabled={loading}
      />
      <CustomModal
        open={open}
        onClose={() => setOpen(false)}
        variant={status}
        text={message}
        title={title}
      />
      <Dialog
        style={{ borderRadius: "20px" }}
        open={openSyarat}
        scroll={"paper"}
        onClose={() => setOpenSyarat(false)}
      >
        <DialogContent>
          <Box>
            <SyaratDanKetentuan />
          </Box>
        </DialogContent>
      </Dialog>
      <CustomModal
        isCustom={true}
        open={openOtp}
        onClose={() => setOpenOtp(false)}
      >
        <OTPRegister
          loading={() => {}}
          onClose={() => onClose()}
          onCloseOtp={() => setOpenOtp(false)}
          onSucces={() => onSuccess()}
        />
      </CustomModal>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <form style={{ width: "400px" }} onSubmit={handleConfirmation}>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={600}
            color={"#404040"}
            textAlign={"center"}
            style={{ marginBottom: "20px" }}
          >
            PENDAFTARAN
          </Typography>
          <Select
            name={REGISTER_FORM_NAME.TITLE}
            optionData={REGISTER_TITLE_DATA}
            onChange={handleChange}
            label={"Select Title"}
            value={inputRegister.title}
          />
          <Box sx={{ width: "100%", marginLeft: "-5px", marginTop: "10px" }}>
            <InputText
              name={REGISTER_FORM_NAME.FIRST_NAME}
              placeholder={REGISTER_CONST.PLC_FIRST_NAME}
              label={"Nama Depan"}
              onChange={handleChange}
              length={50}
              required={true}
              width={`100%`}
              value={inputRegister.firstName}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
            sx={{ width: "100%", marginBottom: "10px" }}
          >
            {inputRegister.firstName.length !== 0 &&
              !checkFirstName(inputRegister.firstName) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    style={{
                      color: "red",
                      fontSize: "11px",
                      fontStyle: "italic",
                      textAlign: "left",
                    }}
                  >
                    *Nama depan minimal 2 karakter, hanya terdiri dari huruf
                    alfabet
                  </span>
                  <span
                    style={{
                      color: "red",
                      fontSize: "11px",
                      fontStyle: "italic",
                    }}
                  >
                    *Setiap kata nama depan diawali dengan huruf kapital
                  </span>
                </div>
              )}
          </Box>
          <Box sx={{ width: "100%", marginLeft: "-5px", marginTop: "10px" }}>
            <InputText
              name={REGISTER_FORM_NAME.LAST_NAME}
              placeholder={REGISTER_CONST.PLC_LAST_NAME}
              label={"Nama Belakang"}
              length={50}
              onChange={handleChange}
              required={false}
              width={`100%`}
              value={inputRegister.lastName}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
            sx={{ width: "100%", marginBottom: "10px" }}
          >
            {inputRegister.lastName.length !== 0 &&
              !checkFirstName(inputRegister.lastName) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      color: "red",
                      fontSize: "11px",
                      fontStyle: "italic",
                    }}
                  >
                    *Nama belakang minimal 2 karakter, hanya terdiri dari huruf
                    alfabet
                  </span>
                  <span
                    style={{
                      color: "red",
                      fontSize: "11px",
                      fontStyle: "italic",
                    }}
                  >
                    *Setiap kata nama belakang diawali dengan huruf kapital
                  </span>
                </div>
              )}
          </Box>
          <Box sx={{ width: "100%", marginLeft: "-5px", marginTop: "10px" }}>
            <InputText
              name={REGISTER_FORM_NAME.PHONE}
              label={REGISTER_CONST.PLC_PHONE_NUMBER}
              placeholder={REGISTER_CONST.PLC_PHONE_IDN}
              onChange={handleChange}
              required={true}
              width={`100%`}
              length={
                inputRegister.phone.charAt(0) === "0"
                  ? 12
                  : inputRegister.phone.charAt(0) === "+"
                  ? 14
                  : ""
              }
              value={inputRegister.phone}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            {inputRegister.phone.length !== 0 &&
              !/^(\+62|0)8[1-9][0-9]{6,16}$/.test(inputRegister.phone) && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    fontStyle: "italic",
                  }}
                >
                  *Nomor handphone tidak sesuai format
                </span>
              )}
          </Box>
          <Box sx={{ width: "100%", marginLeft: "-5px", marginTop: "10px" }}>
            <InputText
              name={REGISTER_FORM_NAME.USERNAME}
              placeholder={REGISTER_CONST.PLC_USERNAME}
              label={"Username"}
              onChange={handleChange}
              required={true}
              length={20}
              width={`100%`}
              value={inputRegister.userName}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
            sx={{ width: "100%", marginBottom: "10px" }}
          >
            {inputRegister.userName.length !== 0 &&
              !/^(?=.*[a-z])(?=.*\d)[a-z\d]{6,}$/.test(
                inputRegister.userName
              ) && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    fontStyle: "italic",
                    textAlign: "left",
                  }}
                >
                  *Username harus terdiri dari 6 karakter dan 1 angka, tidak ada
                  huruf kapital
                </span>
              )}
          </Box>
          <Box sx={{ width: "100%", marginLeft: "-5px", marginTop: "10px" }}>
            <InputText
              name={REGISTER_FORM_NAME.PASSWORD}
              placeholder={REGISTER_CONST.PLC_PASSWORD}
              onChange={handleChange}
              label={"Kata Sandi"}
              required={true}
              width={`100%`}
              type={INPUT_TYPE.INPUT_PASSWORD}
              length={50}
              value={inputRegister.password}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
            sx={{ width: "100%", marginBottom: "10px" }}
          >
            {inputRegister.password.length !== 0 &&
              !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])[A-Za-z\d\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]{8,}$/.test(
                inputRegister.password
              ) && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    fontStyle: "italic",
                    textAlign: "left",
                  }}
                >
                  *Kata sandi harus terdiri dari 8 karakter, 1 huruf kapital, 1
                  angka, 1 karakter special
                </span>
              )}
          </Box>
          <Box sx={{ width: "100%", marginLeft: "-5px", marginTop: "10px" }}>
            <InputText
              name={REGISTER_FORM_NAME.CONFIRMATION_PASSWORD}
              placeholder={REGISTER_CONST.PLC_CONFIRM_PASSWORD}
              onChange={handleChange}
              label={"Konfirmasi Kata Sandi"}
              required={true}
              length={50}
              width={`100%`}
              type={INPUT_TYPE.INPUT_PASSWORD}
              value={inputRegister.confirmPassword}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
            sx={{ width: "100%", marginBottom: "10px" }}
          >
            {inputRegister.confirmPassword !== inputRegister.password && (
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  fontStyle: "italic",
                  textAlign: "left",
                }}
              >
                *Password tidak sama
              </div>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              data-testid={"syaratdanketentuan"}
              type={INPUT_TYPE.INPUT_CHECKBOX}
              onClick={() =>
                checkedSyarat ? setCheckedSyarat(false) : setCheckedSyarat(true)
              }
            />
            <Typography variant="body2">
              Menerima{" "}
              <a
                onClick={() => setOpenSyarat(true)}
                target="_blank"
                rel="noreferrer"
                style={{
                  marginRight: "3px",
                  color: "red",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Syarat dan ketentuan
              </a>
              yang berlaku
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              name={"registerBtn"}
              disabled={
                inputRegister.title.length === 0 ||
                !checkedSyarat ||
                !checkFirstName(inputRegister.firstName) ||
                !checkFirstName(inputRegister.lastName) ||
                inputRegister.firstName.length === 0 ||
                !/^(\+62|0)8[1-9][0-9]{6,16}$/.test(inputRegister.phone) ||
                inputRegister.lastName.length === 0 ||
                inputRegister.phone.length === 0 ||
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])[A-Za-z\d\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]{8,}$/.test(
                  inputRegister.password
                ) ||
                inputRegister.password !== inputRegister.confirmPassword ||
                !/^(?=.*[a-z])(?=.*\d)[a-z\d]{6,}$/.test(inputRegister.userName)
                  ? true
                  : false
              }
              value={"Kirim"}
              width={`330px`}
              height={`45px`}
            />
          </Box>
        </form>
      </Box>
    </>
  );
};

export default NewRegister;

NewRegister.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};
