const INITIAL_STATE = {
    data: {},
  };
  
  const registerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "REGISTER_USER":
        return {
          ...state,
          data: action.payload,
        };
      case "CLEAR_REGISTER":
        return {
          ...state,
          data : {}
        }  
      default:
        return state;
    }
  };
  
  export default registerReducer;
  