const INITIAL_STATE = {
    data: {
      isValidated : false,
    },
    token: ''
  };
  
  const validateTokenReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "VALIDATE_TOKEN":
        return {
          ...state,
          data: action.payload,
        };
      case "ADD_TOKEN":
        return {
          ...state,
          token: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default validateTokenReducer;
  