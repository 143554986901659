import { Dialog, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import styles from "./Notification.module.css";
import PropTypes from "prop-types";
import Button from "../Button/Button";

export default function CustomConfirmation({
  onClose,
  open,
  onConfirm,
  disabled,
}) {
  // Material-UI State
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={
        matches ? styles.confirmationDialogMobile : styles.confirmationDialog
      }
    >
      <Box
        className={
          matches
            ? styles.confirmationContainerMobile
            : styles.confirmationContainer
        }
      >
        {matches ? (
          <hr className={styles.sliderLine} onClick={() => onClose()} />
        ) : (
          ""
        )}
        <Typography
          fontSize={22}
          fontWeight={600}
          sx={{ marginBottom: "40px", marginTop: "0px" }}
        >
          KONFIRMASI
        </Typography>
        <Typography
          fontSize={16}
          fontWeight={500}
          sx={{ marginBottom: "30px" }}
        >
          Klik tombol{" "}
          <span style={{ color: "red", fontWeight: "600" }}>“KIRIM”</span>
          bila
          <br />
          data sudah sesuai.
        </Typography>
        <Typography
          fontSize={16}
          fontWeight={500}
          sx={{ marginBottom: matches ? "50px" : "100px" }}
        >
          Klik tombol{" "}
          <span style={{ color: "#404040", fontWeight: "600" }}>“BATAL”</span>{" "}
          bila
          <br />
          data ingin dicek kembali
        </Typography>
        <Button
          onClick={() => onConfirm()}
          value={"Kirim"}
          width={"90%"}
          height={"50px"}
          background={"#D81922"}
          color={"white"}
          fontWeight={500}
          fontSize={18}
          disabled={disabled}
        />
        <Button
          onClick={() => onClose()}
          value={"Batal"}
          width={"90%"}
          height={"50px"}
          background={"white"}
          color={"black"}
          fontWeight={500}
          fontSize={18}
          disabled={disabled}
        />
      </Box>
    </Dialog>
  );
}

CustomConfirmation.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  disabled: PropTypes.bool,
};
