import { Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { forgetPassAction } from "../../redux/action/forgetPassAction";
import { postNoRestrict } from "../../services/Services";
import Button from "../atomic/Button/Button";
import InputText from "../atomic/Input/InputText";
import styles from "./ForgetPassword.module.css";
import PropTypes from "prop-types";
import CustomNotification from "../atomic/Alert/CustomNotification";

export default function ForgetPassword({ onClose, onOpenOtp }) {
  //Redux State
  const dispatch = useDispatch();

  // Local State
  const [phone, setPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [errorNotif, setErrorNotif] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmitRequestPhone = (e) => {
    e.preventDefault();
    setLoading(true);
    let inputData = {
      phone: `${phone}`,
    };
    if (/^(\+62|62|0)8[1-9][0-9]{7,10}$/.test(inputData.phone)) {
      postNoRestrict("/v3/auth/password/forget", inputData)
        .then((res) => {
          // Save Data Phones
          if (
            res.data.code === 200
          ) {
            dispatch(forgetPassAction(phone));
            onOpenOtp();
            onClose();
          } else {
            setErrorMessage({
              spamming:
                res.data.note,
            });
            setErrorNotif(true);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (
            err.response.data.code ===
            400
          ) {
            setErrorMessage({
              phoneRegex: false,
              spamming:
                "Anda sudah melakukan aksi ini sebelumnya, mohon tunggu beberapa saat lagi",
            });
            setErrorNotif(true);
          } else {
            setErrorMessage({
              phoneRegex: false,
              phoneValidate: true,
              spamming: err.response.data.note,
            });
            // setErrorNotif(true);
          }
        });
    } else {
      setErrorMessage({
        phoneRegex: true,
        phoneValidate: false,
      });
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <CustomNotification
        onClose={() => setErrorNotif(false)}
        open={errorNotif}
        message={errorMessage.spamming}
      />
      <Typography
        className={styles.lupaKataSandi}
        variant="body2"
        fontSize={24}
        fontWeight={600}
        color={"#404040"}
      >
        LUPA KATA SANDI
      </Typography>
      <div className={styles.formContainer}>
        <p>
          Kode atur ulang akan dikirimkan ke nomor handphone yang didaftarkan.
        </p>
        <form onSubmit={handleSubmitRequestPhone}>
          <InputText
            type={"text"}
            placeholder={"Ketik nomor handphone"}
            label={"Nomor Handphone"}
            width={`90%`}
            value={phone}
            length={
              phone.charAt(0) === "0" ? 12 : phone.charAt(0) === "+" ? 14 : ""
            }
            error={errorMessage}
            onChange={(e) => {
              if (/^[0-9+]*$/.test(e.target.value)) {
                setPhone(e.target.value);
              }
            }}
          />
          <div className={styles.buttonKirim}>
            <Button
              width={`330px`}
              height={`50px`}
              value={"Kirim"}
              disabled={loading}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

ForgetPassword.propTypes = {
  onClose: PropTypes.func,
  onOpenOtp: PropTypes.func,
};
