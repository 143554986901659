import GroChatImage from '../../assets/grochatLogo'
import './style.css'
export default function ForgetPasswordGrochat(){
  return(
    <>
        <div class="card">
        <div className='logo'><GroChatImage/></div>
        <div className='title'>Password Reset Request Successful</div>
        <div className='paragraph'>
            We have received your request to reset your password. To continue the reset process, open Grochat Desktop and enter the code we sent to your email.
        </div>
    </div>
    </>
  )
}