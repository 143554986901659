import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import { postData } from "../../services/Services";
import Button from "../atomic/Button/Button";
import InputText from "../atomic/Input/InputText";
import CustomModal from "../atomic/Modal/CustomModal";
import { css } from "@emotion/react";
import WarningIcon from "../../assets/warning.png";
import ReloadIcon from "../../assets/reload.png";
import { PAGE_CONSTANT } from "../../constant/pageConstant";
import FailedIcon from "../../assets/failed.png";

const WebValidate = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const token = searchParams.get("token");
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [loginData, setLoginData] = useState({
    userName: "",
    password: "",
  });
  const [typePassword, setTypePassword] = useState(false);
  const successURI = searchParams.get("successURI");
  const resourceID = searchParams.get("resourceID");
  const resourceName = searchParams.get("resourceName");
  const cancelURI = searchParams.get("cancel");
  const invitationCode = searchParams.get("invitationCode");
  const userType = searchParams.get("userType");
  const requestID = searchParams.get("requestID");
  const twofa_type = searchParams.get("2fa_type");

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("error");
  const [message, setMessage] = useState("");
  const [modalRegister, setModalRegister] = useState(false);
  const [validate, setValidate] = useState(false);
  const [, setIsLogin] = useState(false);
  const [timeoutModal, setTimeoutModal] = useState(false);
  const [clientId, setClientId] = useState("");
  const [inlineError, setInlineError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [internetError, setInternetError] = useState(false);
  const [errorAccountType, setErrorAccountType] = useState(false);

  const [title, setTitle] = useState(null);

  const validateInvitation = () => {
    setLoading(true);
    const data = {
      successURI: successURI,
      resourceID: resourceID,
      resourceName: resourceName,
      cancelURI: cancelURI,
      invitationCode: invitationCode,
      userType: userType,
    };
    postData("/v3/auth/user/authenticate/invitation", token, data)
      .then((res) => {
        setLoading(false);
        if (res.data.status === 1) {
          setValidate(false);
          if (
            res.data.data.nexsoft.payload.data.content.client_id === undefined
          ) {
            setModalRegister(true);
            setIsLogin(true);
          } else {
            setClientId(res.data.data.nexsoft.payload.data.content.client_id);
          }
        } else if (res.data.status === 300047) {
          setStatus("error");
          setMessage(res.data.note);
          setValidate(true);
          setTimeout(() => {
            window.location.replace(cancelURI);
          }, 5000);
        } else {
          setStatus("error");
          setMessage(res.data.note.nexsoft?.payload.status.message);
          setValidate(true);
          setTimeout(() => {
            window.location.replace(cancelURI);
          }, 5000);
        }
      })
      .catch((err) => {
        if (err) {
          setStatus("error");
          setMessage("Terjadi kesalahan mohon kontak developer Anda.");
          setTitle("System Error!");
        }
      });
  };
  useEffect(() => {
    validateInvitation();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTimeoutModal(true);
    }, PAGE_CONSTANT.TIMEOUT_PAGE);
  }, []);

  const handleClickShowPassword = () => {
    if (typePassword) {
      setTypePassword(false);
    } else {
      setTypePassword(true);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setInlineError(false);
    const finalData = {
      ...loginData,
      requestID: requestID,
      redirectURI: successURI,
      resourceID: resourceID,
      invitationCode: invitationCode,
      "2fa_type": twofa_type ? twofa_type : "",
    };
    setLoading(true);
    postData("/v3/auth/login/pwa/verification", token, finalData)
      .then((res) => {
        setLoading(false);
        if (res.data.status === 1) {
          setOpen(true);
          setStatus("success");
          setMessage("Akun GroChat Anda telah berhasil dihubungkan");
          setTimeout(() => {
            window.location.replace(
              `${successURI}?success=true&invitation_code=${invitationCode}&client_id=${clientId}`
            );
          }, 5000);
        } else if (res.data.status === 1000037) {
          setInlineError(true);
          setErrorText("Username atau Password tidak sesuai");
        } else if (res.data.status === 300050) {
          setErrorAccountType(true);
        } else if (res.data.status === 300036) {
          setOpen(true);
          setStatus("error");
          setMessage(res.data.note);
          setTimeout(() => {
            window.location.replace(`${cancelURI}`);
          }, 5000);
        } else if (res.data.status === 300020) {
          if (res.data.note.nexsoft.payload.status.code === "E-1-AUT-SRV-001") {
            setInlineError(true);
            setErrorText("Username atau Password tidak sesuai");
          } else {
            setOpen(true);
            setStatus("error");
            setMessage(res.data.note.nexsoft.payload.status.message);
            setTitle("Tidak Berhasil!");
          }
        } else {
          setOpen(true);
          setStatus("error");
          setMessage("");
        }
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
        }
      });
  };

  const handleChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const reloadPage = () => {
    unlockInvitationCode();
  };

  const unlockInvitationCode = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (!navigator.onLine) {
      setInternetError(true);
      setStatus("error");
      setMessage("Jaringan terputus. Silakan ulangi lagi");
    }
  }, [navigator.onLine]);

  return (
    <Box padding={"20px"} sx={{ height: "100vh" }} marginTop="20px">
      {loading && (
        <Box
          position={"absolute"}
          width="100vw"
          top="0"
          left="0"
          height="100vh"
          zIndex="9999999999"
          display="flex"
          alignItems="center"
          justifyContent={"center"}
        >
          <Box
            position={"absolute"}
            width="100vw"
            top="0"
            left="0"
            sx={{ background: "black", opacity: "10%" }}
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent={"center"}
          ></Box>
          <PuffLoader css={css} color={"black"} loading={loading} size={150} />
        </Box>
      )}
      {clientId.length === 0 ? (
        <Typography textAlign={"left"}>
          Silahkan masukkan <b>Nama Pengguna</b> dan{" "}
          <b>Kata Sandi Akun Grochat</b> untuk menghubungkan email dengan
          <b> Grochat Account</b>
        </Typography>
      ) : (
        <Typography textAlign={"left"}>
          Silahkan masukkan <b>Nama Pengguna</b> dan{" "}
          <b>Kata Sandi Akun Grochat</b> yang terdaftar pada <i>email</i> (email
          user) untuk melakukan validasi akun.
        </Typography>
      )}

      <Box>
        <form
          onSubmit={handleSubmit}
          style={{
            width: "100%",
            paddingBlock: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <InputText
            type={"text"}
            name={"userName"}
            label={"Nama Pengguna"}
            placeholder={"Ketik user ID / nomor ponsel anda"}
            onChange={handleChange}
            value={loginData.userName}
            width={matches ? "100%" : "100%"}
          />
          <FormControl
            sx={{ width: matches ? "100%" : "100%" }}
            variant="standard"
          >
            <InputLabel htmlFor="standard-adornment-password" shrink>
              Kata Sandi
            </InputLabel>
            <Input
              id="standard-adornment-password"
              type={typePassword ? "text" : "password"}
              onChange={handleChange}
              name={"password"}
              value={loginData.password}
              placeholder={"Ketik kata sandi"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {typePassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          {inlineError && (
            <i
              style={{
                fontSize: "14px",
                color: "red",
                marginBottom: "10px",
                marginTop: "-5px",
                width: "100%",
                textAlign: "left",
              }}
            >
              {errorText}
            </i>
          )}
          <Button
            value={clientId.length === 0 ? "HUBUNGKAN" : "VALIDASI"}
            width={"80%"}
            height={"45px"}
            background={"#D81922"}
            color={"white"}
          />
        </form>
      </Box>

      <CustomModal
        open={internetError}
        onClose={() => setInternetError(false)}
        variant={status}
        text={message}
      />
      <CustomModal
        open={validate}
        onClose={() => window.location.replace(cancelURI)}
        variant={status}
        text={message}
      />
      <CustomModal
        open={open}
        onClose={() => setOpen(false)}
        variant={status}
        text={message}
        title={title}
      />
      <CustomModal
        isCustom={true}
        open={errorAccountType}
        onClose={() => setErrorAccountType(false)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
            width: "100%",
            maxWidth: !matches ? "300px" : "100%",
          }}
        >
          <Box>
            <img src={FailedIcon} style={{ width: "150px" }} />
          </Box>
          <Typography fontWeight={600} fontSize={20} textAlign="center">
            Tidak Berhasil!
          </Typography>
          <Typography fontWeight={400} fontSize={16} textAlign="center">
            Undangan tautan dan akun GroChat tidak valid
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Button
              value={clientId.length > 0 ? "Validasi Lagi" : "Hubungkan Lagi"}
              width={"100%"}
              height={"45px"}
              background={"#D81922"}
              color={"white"}
              onClick={() => {
                setErrorAccountType(false);
                setIsLogin(false);
              }}
            />
            {clientId.length === 0 && (
              <Button
                value={"Lanjut Registrasi"}
                width={"100%"}
                height={"45px"}
                background={"white"}
                border="1px solid red"
                color={"#D81922"}
                onClick={() =>
                  navigate(
                    `/register?token=${token}&successURI=${successURI}&resourceID=${resourceID}&resourceName=${resourceName}&cancel=${cancelURI}&invitationCode=${invitationCode}&userType=${userType}&requestID=${requestID}`
                  )
                }
              />
            )}
          </Box>
        </Box>
      </CustomModal>

      <CustomModal isCustom={true} open={modalRegister} onClose={() => {}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
            width: "100%",
            maxWidth: !matches ? "300px" : "100%",
          }}
        >
          <Box>
            <img src={WarningIcon} style={{ width: "150px" }} />
          </Box>
          <Typography fontWeight={600} fontSize={20} textAlign="center">
            Email belum terdaftar!
          </Typography>
          <Typography fontWeight={600} fontSize={16} textAlign="center">
            Anda bisa gunakan Akun Grochat yang sudah terdaftar atau Registrasi
            Pengguna Baru.
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Button
              value={"HUBUNGKAN"}
              width={"100%"}
              height={"45px"}
              background={"#D81922"}
              color={"white"}
              onClick={() => {
                setIsLogin(true);
                setModalRegister(false);
              }}
            />
            <Button
              value={"REGISTRASI"}
              width={"100%"}
              height={"45px"}
              background={"white"}
              border="1px solid red"
              color={"#D81922"}
              onClick={() =>
                navigate(
                  `/register?token=${token}&successURI=${successURI}&resourceID=${resourceID}&resourceName=${resourceName}&cancel=${cancelURI}&invitationCode=${invitationCode}&userType=${userType}&requestID=${requestID}`
                )
              }
            />
          </Box>
        </Box>
      </CustomModal>

      <CustomModal
        isCustom={true}
        open={timeoutModal}
        onClose={() => setTimeoutModal(false)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            maxWidth: !matches ? "300px" : "100%",
            gap: "20px",
            width: "100%",
          }}
        >
          <Box>
            <img src={ReloadIcon} style={{ width: "150px" }} />
          </Box>
          <Typography fontWeight={600} fontSize={20} textAlign="center">
            Muat ulang!
          </Typography>
          <Typography fontWeight={400} fontSize={16} textAlign="center">
            Anda terlalu lama tidak aktif, silahkan{" "}
            <span style={{ fontWeight: 600 }}>Muat Ulang</span> untuk kembali
            mengakses halaman.
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Button
              value={"Muat Ulang"}
              width={"100%"}
              height={"45px"}
              background={"#D81922"}
              color={"white"}
              onClick={() => reloadPage()}
            />
          </Box>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default WebValidate;
