import React from "react";
import styles from "./Button.module.css";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

export default function Button(props) {
  const {
    onClick,
    disabled,
    value,
    name,
    width,
    height,
    background = "#CF0000",
    color = "white",
    border,
    fontWeight = 400,
    fontSize = 16,
    style,
  } = props;
  return (
    <button
      name={name}
      className={styles.buttonStyle}
      disabled={disabled}
      onClick={onClick}
      style={{
        width: `${width}`,
        height: `${height}`,
        background: `${disabled ? "#9d9e9d" : background}`,
        color: `${color}`,
        border: `${border}`,
        ...style,
      }}
    >
      <Typography variant="body1" fontWeight={fontWeight} fontSize={fontSize}>
        {value}
      </Typography>
    </button>
  );
}

Button.propTypes = {
  border: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  fontWeight: PropTypes.number,
  fontSize: PropTypes.number,
  style: PropTypes.object,
};
