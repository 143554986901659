export const validateToken = (payload) => {
    return {
        type : 'VALIDATE_TOKEN',
        payload : payload
    }
}

export const addToken = (payload) => {
    return {
        type : 'ADD_TOKEN',
        payload : payload 
    }
}