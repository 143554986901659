export const otpPhoneHelper = (phone) => {
  if (phone.includes("+")) {
    return `${phone.slice(0, 3)}-${phone.slice(3)}`;
  } else if (phone.charAt(0) === "0") {
    return `+62-${phone.slice(1)}`;
  } else if (phone.charAt(0) === "6") {
    return `+${phone.slice(0, 2)}-${phone.slice(2)}`;
  } else {
    return phone;
  }
};
