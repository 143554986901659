import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./component/error/ErrorFallback";
import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./redux/store";
import { composeWithDevTools } from "redux-devtools-extension";
import { StyledEngineProvider } from "@mui/material";

const store = createStore(rootReducer, composeWithDevTools());
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <StyledEngineProvider>
            <App />
          </StyledEngineProvider>
        </ErrorBoundary>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
