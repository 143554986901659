const INITIAL_STATE = {
  isLogo: true,
};

const homepageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LOGO":
      return {
        ...state,
        isLogo: action.payload,
      };
    default:
      return state;
  }
};

export default homepageReducer;
