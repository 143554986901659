import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ErrorNotFound from "../component/error/ErrorNotFound";
import Login from "../component/Login/Login";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Migrations from "../component/Migration/Migrations";
import WebValidate from "../component/WebValidate/WebValidate";
import RegisterInvitation from "../component/RegisterInvitation/RegisterInvitation";
import Delete2 from "../component/Delete/Delete2"

export default function RoutersDelete() {
  // Redux State
  const isValidated = useSelector(
    (state) => state.validateData.data.isValidated
  );

  const PrivateRoute = ({ children }) => {
    return isValidated ? children : <Navigate to="/login" />;
  };
  PrivateRoute.propTypes = {
    children: PropTypes.object,
  };
  return (
    <Routes>
      <Route exact path="/delete" element={<Delete2 />} />
    </Routes>
  );
}
