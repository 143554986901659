import { REGISTER_CONST } from "../../constant/registerConst";

export const REGISTER_TITLE_DATA = [
    {
        display : "Tuan ",
        value : REGISTER_CONST.REGISTER_TITLE_MR
    },
    {
        display : "Nona ",
        value : REGISTER_CONST.REGISTER_TITLE_MRS
    },
    {
        display : "Nyonya ",
        value : REGISTER_CONST.REGISTER_TITLE_MS
    },
]