import React from "react";
import styles from "../Input/Select.module.css";
import PropTypes from "prop-types";
import { FormControl, InputLabel, NativeSelect } from "@mui/material";

export default function Select(props) {
  const {
    name = "",
    optionData = [],
    error = false,
    onChange,
    required,
    label,
    defaultValue
  } = props;

  return (
    <div className={styles.selectContainer}>
      <FormControl fullWidth>
        <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink={true}>
          {label}
        </InputLabel>
        <NativeSelect
          defaultValue={defaultValue}
          
          inputProps={{
            name: `${name}`,
            required: required,
            onChange: onChange,
            id: "uncontrolled-native",
          }}
        >
          <option value="" selected></option>
          {optionData.map((item, index) => {
            return (
              <option value={item.value} key={index}>
                {item.display}
              </option>
            );
          })}
        </NativeSelect>
      </FormControl>

      {error && <i className={styles.selectWarning}>*Mohon pilih gelar anda</i>}
    </div>
  );
}

Select.propTypes = {
  name: PropTypes.string,
  optionData: PropTypes.array,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  label: PropTypes.string,
  defaultValue: PropTypes.string
};
