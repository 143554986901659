import React from "react";
import styles from "./LogoContainer.module.css";
import Logo  from '../../assets/group_logo.png';
export default function LogoContainer() {
  return (
    <div className={styles.logoContainer}>
      <img src={Logo} alt={"Logo GroUp"} />
    </div>
  );
}
