import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postNoRestrict } from "../../services/Services";
import Button from "../atomic/Button/Button";
import OTP from "./OTP";
import styles from "./OTPRegister.module.css";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import CustomNotification from "../atomic/Alert/CustomNotification";
import { clearRegister } from "../../redux/action/registerAction";
import { otpPhoneHelper } from "../../helper/otpPhoneHelper";

export default function OTPRegisterMobile({
  onClose,
  onCloseOtp,
  onSucces,
  activation,
  phone,
  activationURL,
  invitationCode,
  loading,
}) {
  // Local State
  const [otpData, setOtpData] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });
  const [resendOtp, setResendOtp] = useState(1);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Redux State
  const dispatch = useDispatch();
  const userRegister = useSelector((state) => state.registerData.data);

  const handleChange = (e) => {
    setOtpData({
      ...otpData,
      [e.target.name]: e.target.value,
    });
  };

  const handleResendOtp = () => {
    postNoRestrict("/v3/auth/registration/activation/phone/resending", {
      user_id: userRegister.user_id,
      phone: userRegister.phone,
    })
      .then((res) => {
        if (res.status === 200) {
          setResendOtp(resendOtp + 1);
        }
      })
      .catch((err) => {
        if (err) {
          setErrorMessage("Mohon maaf, terjadi kesahalan pada sistem kami");
          setOpenErrorAlert(true);
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let inputData = {};
    loading(true);
    if (activation) {
      inputData = {
        user_id: userRegister.user_id,
        phone: `${otpPhoneHelper(phone)}`,
        otp_code: `${otpData.otp1}${otpData.otp2}${otpData.otp3}${otpData.otp4}${otpData.otp5}${otpData.otp6}`,
        invitationCode: invitationCode,
      };
    } else {
      inputData = {
        user_id: userRegister.user_id,
        phone: `${userRegister.phone}`,
        otp_code: `${otpData.otp1}${otpData.otp2}${otpData.otp3}${otpData.otp4}${otpData.otp5}${otpData.otp6}`,
      };
    }
    postNoRestrict("/v3/auth/registration/activation/phone", inputData)
      .then((res) => {
        if (res.data.status === 1) {
          if (activation) {
            window.location.href = activationURL;
          }
          onClose();
          onCloseOtp();
          onSucces();
          dispatch(clearRegister());
        } else {
          setErrorMessage("kode verifikasi salah, coba lagi");
          setOpenErrorAlert(true);
        }
        loading(false);
      })
      .catch((err) => {
        if (err) {
          setErrorMessage("Mohon maaf, terjadi kesahalan pada sistem kami");
          setOpenErrorAlert(true);
        }
        loading(false);
      });
  };

  return (
    <div className={styles.otpRegisterMobile}>
      <CustomNotification
        onClose={() => setOpenErrorAlert(false)}
        open={openErrorAlert}
        message={errorMessage}
      />
      <hr className={styles.sliderLine} onClick={onClose} />
      <Typography
        variant="h5"
        component="h5"
        fontWeight={600}
        color={"#404040"}
        textAlign={"center"}
      >
        {"OTP PENDAFTARAN"}
      </Typography>
      {resendOtp === 3 ? (
        <div className={styles.alertOtp}>
          <ErrorOutline color="yellow" fontSize={"large"} />
          <Typography variant="body2" fontSize={14}>
            Silahkan hubungi 021-2222-0138 untuk bantuan
          </Typography>
        </div>
      ) : (
        ""
      )}
      {activation ? (
        <div className={styles.descOtp}>
          Masukkan Kode OTP yang dikirimkan ke nomor
          <br />
          Handphone Untuk Aktivasi
        </div>
      ) : (
        <div className={styles.descOtp}>
          Masukkan Kode OTP yang dikirimkan ke nomor
          <br />
          Handphone Untuk Aktivasi GroChat Account
        </div>
      )}

      <div className={styles.inputOTPContainer}>
        <OTP handleChange={handleChange} otpData={otpData} />

        {resendOtp < 3 ? (
          <>
            <Typography className={styles.resendOtpTimes} fontSize={12}>
              OTP ke - {`${resendOtp}`}
            </Typography>
            <Typography fontSize={14}>Belum terima kode ?</Typography>
            <Typography
              className={styles.kirimUlang}
              fontSize={16}
              onClick={handleResendOtp}
            >
              Kirim Ulang
            </Typography>
          </>
        ) : (
          ""
        )}
        <div style={{ marginBottom: "50px" }}>
          <Button value={"Kirim"} onClick={handleSubmit} width={`300px`} />
        </div>
      </div>
    </div>
  );
}
OTPRegisterMobile.propTypes = {
  onClose: PropTypes.func,
  onCloseOtp: PropTypes.func,
  onSucces: PropTypes.func,
  activation: PropTypes.bool,
  phone: PropTypes.string,
  activationURL: PropTypes.string,
  loading: PropTypes.func,
  invitationCode: PropTypes.string
};
