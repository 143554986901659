import React from "react";
import styles from "./OTPRegister.module.css";
import PropTypes from "prop-types";

export default function OTP({ otpData, handleChange }) {
  const inputFocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };
  return (
    <div className={styles.otpContainer}>
      <form>
        <input
          name="otp1"
          type="number"
          placeholder={0}
          value={otpData.otp1}
          onChange={handleChange}
          tabIndex={1}
          maxLength={1}
          onKeyUp={(e) => inputFocus(e)}
        />
        <input
          name="otp2"
          type="number"
          placeholder={0}
          value={otpData.otp2}
          onChange={handleChange}
          tabIndex={2}
          maxLength={1}
          onKeyUp={(e) => inputFocus(e)}
        />
        <input
          name="otp3"
          type="number"
          placeholder={0}
          value={otpData.otp3}
          onChange={handleChange}
          tabIndex={3}
          maxLength={1}
          onKeyUp={(e) => inputFocus(e)}
        />
        <input
          name="otp4"
          type="number"
          placeholder={0}
          value={otpData.otp4}
          onChange={handleChange}
          tabIndex={4}
          maxLength={1}
          onKeyUp={(e) => inputFocus(e)}
        />
        <input
          name="otp5"
          type="number"
          placeholder={0}
          value={otpData.otp5}
          onChange={handleChange}
          tabIndex={5}
          maxLength={1}
          onKeyUp={(e) => inputFocus(e)}
        />
        <input
          name="otp6"
          type="number"
          placeholder={0}
          value={otpData.otp6}
          onChange={handleChange}
          tabIndex={6}
          maxLength={1}
          onKeyUp={(e) => inputFocus(e)}
        />
      </form>
    </div>
  );
}

OTP.propTypes = {
  otpData: PropTypes.object,
  handleChange: PropTypes.func,
  inputFocus: PropTypes.func,
};
