import { combineReducers } from "redux";
import forgetPassReducer from "./reducer/forgetPassReducer";
import homepageReducer from "./reducer/homepageReducer";
import registerReducer from "./reducer/registerReducer";
import userReducer from "./reducer/userReducer";
import validateTokenReducer from "./reducer/validateTokenReducer";

const appReducer = combineReducers({
  userData: userReducer,
  registerData: registerReducer,
  forgetPassData: forgetPassReducer,
  validateData: validateTokenReducer,
  homepage: homepageReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "DESTROY_SESSION") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
