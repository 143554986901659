import React, {useState} from "react";
import styles from "../../App.module.css";
import {Box, FormControl, IconButton, Input, InputAdornment, InputLabel} from "@mui/material";
import {GroUpLogo, MessageLogo, SuccessDeleteIcon} from "../../assets"
import InputText from "../atomic/Input/InputText";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Button from "../atomic/Button/Button";
import style from "./Delete.module.css";
import {postData, postDataDelete, postLogin} from "../../services/Services";
import CustomModal from "../atomic/Modal/CustomModal";
import CustomNotification from "../atomic/Alert/CustomNotification";
import CustomSuccessNotification from "../atomic/Alert/CustomSuccessNotification";

const Delete2 = () => {
  // Local State Delete
  const [loginData, setLoginData] = useState({
    userName: "",
    password: "",
  });
  const [typePassword, setTypePassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorLogin, setErrorLogin] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [loginErrorModal, setLoginErrorModal] = useState(false);
  const [openLoginSuccess, setOpenLoginSuccess] = useState(false);
  const [otpSuccess] = useState(true);
  const [token, setToken] = useState("")
  const [successDelete, setSuccessDelete] = useState(false)
  const [delete3, setDelete3] = useState(false)
  // Handle Change
  const handleChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickShowPassword = () => {
    if (typePassword) {
      setTypePassword(false);
    } else {
      setTypePassword(true);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const finalData = {
      userName: loginData.userName,
      password: loginData.password
    };
    postLogin("/v5/auth/login", finalData)
      .then((res) => {
        if (res.data.status === 1000037) {
          setErrorMessage("User tidak dikenal");
          setErrorLogin("");
          setLoginErrorModal(false);
        } else if (res.data.status === 1) {
          setErrorMessage("")
          setToken(res.data.data.user_token)
          const finalDatas = {
            phone: loginData.userName,
          };
          postData("/v5/user/delete", res.data.data.user_token, finalDatas)
            .then((res) => {
              if (res.data.status === 1) {
                setOpenLoginSuccess(true)
              } else {
                setToken("")
              }
              setErrorMessage("");
            })
            .catch((err) => {
              if (err.response.data.description === "Pastikan data Anda benar") {
                setErrorMessage("User tidak dikenal");
                setErrorLogin("");
                setLoginErrorModal(false);
              }
            })
        } else if (res.data.status === 300020) {
          setLoginErrorModal(true);
          if (res.data.data.nexsoft.payload.status.code === "E-4-AUT-SRV-003") {
            if (twofa_type === null) {
              setErrorLogin("Terjadi kesalahan mohon kontak developer Anda.");
              setErrorTitle("System Error!");
            } else {
              setErrorLogin(res.data.data.nexsoft.payload.status.message);
            }
          } else {
            setErrorLogin("username atau kata sandi salah, silahkan coba lagi");
            setErrorTitle("Tidak Berhasil!");
          }
          setErrorMessage("");
          setOpenLoginSuccess(false);
        } else {
          setErrorLogin("Gagal melakukan login");
          setErrorMessage("");
          setErrorTitle("Tidak Berhasil!");
          setOpenLoginSuccess(false);
          setLoginErrorModal(true);
        }
      })
      .catch((err) => {
        if (err.response.data.description === "Pastikan data Anda benar") {
          setErrorMessage("User tidak dikenal");
          setErrorLogin("");
          setLoginErrorModal(false);
        }
      })


  };

  const handleDelete = (e) => {
    e.preventDefault();
    setOpenLoginSuccess(false);
    const finalData = {
      phone: loginData.userName,
    };
    postDataDelete("/v5/user/delete", token)
      .then((res) => {
        if (res.data.status === 1) {
          setSuccessDelete(true);
          setErrorMessage("");
        } else {
          setDelete3(true)
        }
        setLoginData({
          ...loginData,
          ["userName"]: "",
          ["password"]: ""
        });
        setToken("")
      })
      .catch((err) => {
        if (err.response.data.description === "Pastikan data Anda benar") {
          setErrorMessage("User tidak dikenal");
          setErrorLogin("");
          setLoginErrorModal(false);
        }
      })
  };

  return (
    <>
      <div className={style.loginContainers}>
        <div className={styles.headerContainer}>
        </div>
        <div className={style.logoContainer} style={{marginBottom: "32px"}}>
          <img src={GroUpLogo} alt={"Logo GroUp"}/>
        </div>
        <div className={styles.title}>
          Hapus Akun
        </div>
        <div className={styles.contentTitle}>
          Silahkan Masukkan Akun Anda
        </div>
        <form
          onSubmit={handleSubmit}
          style={{marginTop: "32px", width: "80vw", maxWidth: "450px"}}>
          <div className={style.usernameContainer}>
            {errorMessage.length > 0 && (
              <i className={style.errorMessage}>{`*${errorMessage}`}</i>
            )}
            <InputText
              type={"text"}
              name={"userName"}
              label={"Nama Pengguna"}
              placeholder={"Ketik user ID / nomor ponsel anda"}
              onChange={handleChange}
              value={loginData.userName}
              width={"100%"}
            />
          </div>
          <div className={style.passwordContainer}>
            <FormControl sx={{width: "100%"}} variant="standard">
              <InputLabel htmlFor="standard-adornment-password" shrink>
                Kata Sandi
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={typePassword ? "text" : "password"}
                onChange={handleChange}
                name={"password"}
                value={loginData.password}
                placeholder={"Ketik kata sandi"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {typePassword ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>

          <div className={style.loginMiddleContainer}>
            <Box sx={{display: "flex", alignItems: "center"}}></Box>
          </div>
          <Box sx={{height: "60px"}}></Box>

          <Button
            value={"KONFIRMASI"}
            width={"100%"}
            height={"45px"}
            background={"#D81922"}
            color={"white"}
          />
          {/*<div className={style.messageContainer}>*/}
          {/*<img src={InformationLogo}/>*/}

          {/*</div>*/}
          <div style={{marginTop: "10px"}}>
            <img src={MessageLogo} style={{width: "80vw", maxWidth: "450px"}}/>
          </div>
        </form>
        {/*<Routers/>*/}
      </div>

      <CustomModal
        isCustom={true}
        open={openLoginSuccess}
        // onClose={() => handleCloseLoginSuccess()}
      >
        <div>
          <div className={style.titleModal}>
            Hapus Akun
          </div>
          <div className={style.messageModal}>
            Apakah Anda yakin ingin menghapus akun anda?
          </div>
          <div style={{
            display: "flex", justifyContent: "end",
            marginBottom: "32px", width: "80vw", maxWidth: "485px"
          }}>
            <div style={{display: "flexWrap", content: "right", marginTop: "2px"}}>
              <Button
                onClick={() => setOpenLoginSuccess(false)}
                value={"Batal"}
                width={"100px"}
                height={"45px"}
                background={"#FFFFFF"}
                color={"red"}
                border={"#CF0000"}
              />
              <Button
                onClick={handleDelete}
                value={"Hapus"}
                width={"100px"}
                height={"45px"}
                background={"#D81922"}
                color={"white"}
              />
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomNotification
        open={loginErrorModal}
        onClose={() => setLoginErrorModal(false)}
        message={errorLogin ? errorLogin : ""}
        title={errorTitle}
      />
      <CustomNotification
        open={delete3}
        onClose={() => setDelete3(false)}
        message={"Akun Gagal dihapus"}
        title={"Tidak Berhasil!"}
      />
      <CustomSuccessNotification
        message={
          "Akun Anda berhasil dihapus"
        }
        onClose={() => setSuccessDelete(false)}
        open={successDelete}
      />
    </>
  );
}

export default Delete2
