import React from "react";
import styles from "./Error.module.css";

export default function ErrorNotFound() {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
          <h1>404</h1>
          <h3>Halaman Tidak Ditemukan</h3>
      </div>
    </div>
  );
}
