import { useEffect, useState } from "react";
import { REGISTER_CONST } from "../../constant/registerConst";
import InputText from "../atomic/Input/InputText";
import Select from "../atomic/Input/Select";
import { REGISTER_FORM_NAME } from "../Register/registerFormName";
import { REGISTER_TITLE_DATA } from "../Register/registerTitleData";
import styles from "../Register/Register.module.css";
import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  Input,
  InputLabel,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ReloadIcon from "../../assets/reload.png";
import { INPUT_TYPE } from "../../constant/inputTypeConst";
import Button from "../atomic/Button/Button";
import { useSearchParams } from "react-router-dom";
// import OTPRegisterMobile from "../OTP/OTPRegisterMobile";
import OTPRegister from "../OTP/OTPRegister";
import { useTheme } from "@mui/system";
import { useDispatch } from "react-redux";
import { getNoRestrict, postLogin } from "../../services/Services";
import { registerUser } from "../../redux/action/registerAction";
import { validateToken } from "../../redux/action/validateToken";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import CustomModal from "../atomic/Modal/CustomModal";
import { PAGE_CONSTANT } from "../../constant/pageConstant";
import SyaratDanKetentuan from "../Register/SyaratDanKetentuan";

const RegisterInvitation = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const successURI = searchParams.get("successURI");
  const resourceID = searchParams.get("resourceID");
  const resourceName = searchParams.get("resourceName");
  const cancelURI = searchParams.get("cancelURI");
  const invitationCode = searchParams.get("invitationCode");
  const userType = searchParams.get("userType");
  const requestID = searchParams.get("requestID");

  const [inputRegister, setInputRegister] = useState({
    title: "",
    firstName: "",
    lastName: "",
    gender: 1,
    phone: "",
    userName: "",
    password: "",
    confirmPassword: "",
  });

  const [errorForm, setErrorForm] = useState({
    genderForm: false,
  });
  const [openActivation, setOpenActivation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [timeoutModal, setTimeoutModal] = useState(false);
  const [openSyarat, setOpenSyarat] = useState(false);
  const [isError, setIsError] = useState(false);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [internetError, setInternetError] = useState(false);

  const [checkedSyarat, setCheckedSyarat] = useState(false);

  const [title, setTitle] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setTimeoutModal(true);
    }, PAGE_CONSTANT.TIMEOUT_PAGE);
  }, []);

  // Material State
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (e.target.name === "phone") {
      setInputRegister({ ...inputRegister, [e.target.name]: e.target.value });
    } else if (e.target.name === "firstName" || e.target.name === "lastName") {
      if (e.target.value.length <= 50) {
        setInputRegister({
          ...inputRegister,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setInputRegister({
        ...inputRegister,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const newValue = {
      ...inputRegister,
      gender:
        parseInt(inputRegister.title) === 3 ||
        parseInt(inputRegister.title) === 4
          ? 2
          : 1,
      firstName: inputRegister.firstName.trim(),
      lastName: inputRegister.lastName.trim(),
      name: `${inputRegister.firstName.trim()} ${inputRegister.lastName.trim()}`,
      invitationCode: invitationCode,
      userType: userType,
    };
    try {
      if (inputRegister.confirmPassword === inputRegister.password) {
        const value = await postLogin("/v3/auth/registration/", newValue);
        setLoading(false);
        if (value.data.status === 1) {
          let saveData = {
            ...value.data.data.userModel,
            user_id: value.data.data.userModel.auth.user_id,
          };
          dispatch(registerUser(saveData));
          setOpenActivation(true);
        } else if (
          value.data.status === 300010 ||
          value.data.status === 1000045 ||
          value.data.status === 1000048 ||
          value.data.status === 1000049
        ) {
          setOpen(true);
          setStatus("error");
          setMessage(value.data.note);
        }
      } else {
        setLoading(false);
        setErrorForm({
          ...errorForm,
          confirmPassword: true,
        });
      }
    } catch (err) {
      if (err) {
        setLoading(false);
        setOpen(true);
        setStatus("error");
        setMessage("Terjadi kesalahan mohon kontak developer Anda.");
        setTitle("System Error!");
      }
    }
  };

  const validatePage = () => {
    setLoading(true);
    getNoRestrict("/v3/auth/token/internal/verify", token)
      .then((res) => {
        setLoading(false);
        if (res.data.status === 1) {
          dispatch(validateToken({ isValidated: true }));
          setValidate(true);
        } else {
          dispatch(validateToken({ isValidated: false }));
          setValidate(false);
        }
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
          setValidate(false);
        }
      });
  };

  useEffect(() => {
    validatePage();
  }, []);

  const reloadPage = () => {
    window.location.reload();
  };

  const checkFirstName = (string) => {
    if (!/^[A-Z][a-z]+(([ ][A-Z][a-z])?[a-z]*)*$/.test(string)) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (
      !checkFirstName(inputRegister.firstName) ||
      !/^(62|\+62|0)8[1-9][0-9]{6,16}$/.test(inputRegister.phone) ||
      inputRegister.title === ""
    ) {
      setIsError(true);
    } else if (
      checkFirstName(inputRegister.firstName) &&
      /^(62|\+62|0)8[1-9][0-9]{6,16}$/.test(inputRegister.phone) &&
      inputRegister.title !== ""
    ) {
      setIsError(false);
    }
  }, [
    inputRegister.firstName,
    inputRegister.lastName,
    inputRegister.phone,
    inputRegister.title,
  ]);

  useEffect(() => {
    if (!navigator.onLine) {
      setInternetError(true);
      setStatus("error");
      setMessage("Jaringan terputus. Silakan ulangi lagi");
    }
  }, [navigator.onLine]);

  return (
    <div style={{ width: "90%" }}>
      {loading && (
        <Box
          position={"absolute"}
          width="100vw"
          top="0"
          left="0"
          height="100vh"
          zIndex="9999999999"
          display="flex"
          alignItems="center"
          justifyContent={"center"}
        >
          <Box
            position={"absolute"}
            width="100vw"
            top="0"
            left="0"
            sx={{ background: "black", opacity: "10%" }}
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent={"center"}
          ></Box>
          <PuffLoader css={css} color={"black"} loading={loading} size={150} />
        </Box>
      )}

      <Dialog
        style={{ borderRadius: "20px" }}
        open={openSyarat}
        scroll={"paper"}
        onClose={() => setOpenSyarat(false)}
        className={styles.syaratKetentuanDialog}
      >
        <DialogContent>
          <Box className={styles.syaratKetentuanContainer}>
            <SyaratDanKetentuan />
          </Box>
        </DialogContent>
      </Dialog>
      {validate ? (
        <form
          onSubmit={handleSubmit}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Select
              name={REGISTER_FORM_NAME.TITLE}
              optionData={REGISTER_TITLE_DATA}
              onChange={handleChange}
              label={"Select Title"}
              value={inputRegister.title}
            />
          </Box>

          <InputText
            name={REGISTER_FORM_NAME.FIRST_NAME}
            placeholder={REGISTER_CONST.PLC_FIRST_NAME}
            label={"Nama Depan"}
            onChange={handleChange}
            length={50}
            required={true}
            width={`100%`}
            value={inputRegister.firstName}
            error={errorForm && errorForm.firstName}
          />
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
            sx={{ width: "100%", marginTop: "-10px", marginBottom: "10px" }}
          >
            {inputRegister.firstName.length !== 0 &&
              !checkFirstName(inputRegister.firstName) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    style={{
                      color: "red",
                      fontSize: "11px",
                      fontStyle: "italic",
                      textAlign: "left",
                    }}
                  >
                    *Nama depan minimal 2 karakter, hanya terdiri dari huruf
                    alfabet
                  </span>
                  <span
                    style={{
                      color: "red",
                      fontSize: "11px",
                      fontStyle: "italic",
                    }}
                  >
                    *Setiap kata nama depan diawali dengan huruf kapital
                  </span>
                </div>
              )}
          </Box>
          <InputText
            name={REGISTER_FORM_NAME.LAST_NAME}
            placeholder={REGISTER_CONST.PLC_LAST_NAME}
            label={"Nama Belakang"}
            length={50}
            onChange={handleChange}
            required={false}
            width={`100%`}
            value={inputRegister.lastName}
          />
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
            sx={{ width: "100%", marginTop: "-10px", marginBottom: "10px" }}
          >
            {inputRegister.lastName.length !== 0 &&
              !checkFirstName(inputRegister.lastName) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      color: "red",
                      fontSize: "11px",
                      fontStyle: "italic",
                    }}
                  >
                    *Nama belakang minimal 2 karakter, hanya terdiri dari huruf
                    alfabet
                  </span>
                  <span
                    style={{
                      color: "red",
                      fontSize: "11px",
                      fontStyle: "italic",
                    }}
                  >
                    *Setiap kata nama belakang diawali dengan huruf kapital
                  </span>
                </div>
              )}
          </Box>
          <FormControl
            sx={{ width: matches ? "100%" : "100%", marginBottom: "10px" }}
            variant="standard"
          >
            <InputLabel htmlFor="standard-adornment-password" shrink>
              Nomor Handphone
            </InputLabel>
            <Input
              id="standard-adornment-password"
              onChange={handleChange}
              name={"phone"}
              value={inputRegister.phone}
              required={true}
              inputProps={{
                maxLength:
                  inputRegister.phone.charAt(0) === "0"
                    ? 12
                    : inputRegister.phone.charAt(0) === "+"
                    ? 14
                    : "",
              }}
              placeholder={"e.g: +628100008008"}
            />
            <Box
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              {inputRegister.phone.length !== 0 &&
                !/^(\+62|0)8[1-9][0-9]{6,16}$/.test(inputRegister.phone) && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      fontStyle: "italic",
                    }}
                  >
                    *Nomor handphone tidak sesuai format
                  </span>
                )}
            </Box>
          </FormControl>
          <InputText
            name={REGISTER_FORM_NAME.USERNAME}
            placeholder={REGISTER_CONST.PLC_USERNAME}
            label={"Username"}
            onChange={handleChange}
            required={true}
            length={20}
            width={`100%`}
            value={inputRegister.userName}
            error={errorForm && errorForm.userName}
          />
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
            sx={{ width: "100%", marginTop: "-10px", marginBottom: "10px" }}
          >
            {inputRegister.userName.length !== 0 &&
              !/^(?=.*[a-z])(?=.*\d)[a-z\d]{6,}$/.test(
                inputRegister.userName
              ) && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    fontStyle: "italic",
                    textAlign: "left",
                  }}
                >
                  *Username harus terdiri dari 6 karakter dan 1 angka, tidak ada
                  huruf kapital
                </span>
              )}
          </Box>
          <InputText
            name={REGISTER_FORM_NAME.PASSWORD}
            placeholder={REGISTER_CONST.PLC_PASSWORD}
            onChange={handleChange}
            label={"Kata Sandi"}
            required={true}
            length={50}
            width={`100%`}
            type={INPUT_TYPE.INPUT_PASSWORD}
            value={inputRegister.password}
            error={errorForm && errorForm.password}
          />
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
            sx={{ width: "100%", marginTop: "-10px", marginBottom: "10px" }}
          >
            {inputRegister.password.length !== 0 &&
              !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])[A-Za-z\d\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]{8,}$/.test(
                inputRegister.password
              ) && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    fontStyle: "italic",
                    textAlign: "left",
                  }}
                >
                  *Kata sandi harus terdiri dari 8 karakter, 1 huruf kapital, 1
                  angka, 1 karakter special
                </span>
              )}
          </Box>
          <InputText
            name={REGISTER_FORM_NAME.CONFIRMATION_PASSWORD}
            placeholder={REGISTER_CONST.PLC_CONFIRM_PASSWORD}
            onChange={handleChange}
            label={"Konfirmasi Kata Sandi"}
            required={true}
            length={50}
            width={`100%`}
            type={INPUT_TYPE.INPUT_PASSWORD}
            value={inputRegister.confirmPassword}
            error={errorForm && errorForm.confirmPassword}
          />
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
            sx={{ width: "100%", marginTop: "-10px", marginBottom: "10px" }}
          >
            {inputRegister.confirmPassword !== inputRegister.password && (
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  fontStyle: "italic",
                  textAlign: "left",
                }}
              >
                *Password tidak sama
              </div>
            )}
          </Box>
          <div className={styles.syaratContainer}>
            <input
              data-testid={"syaratdanketentuan"}
              type={INPUT_TYPE.INPUT_CHECKBOX}
              onClick={() =>
                checkedSyarat ? setCheckedSyarat(false) : setCheckedSyarat(true)
              }
            />
            <Typography variant="body2" sx={{ marginTop: "2px" }}>
              Menerima{" "}
              <a
                className={styles.syaratKetentuan}
                onClick={() => setOpenSyarat(true)}
                target="_blank"
                style={{ marginRight: "5px", textDecoration: "underline" }}
                rel="noreferrer"
              >
                Syarat dan ketentuan
              </a>
              yang berlaku
            </Typography>
          </div>
          <Button
            name={"registerBtn"}
            disabled={
              inputRegister.title.length === 0 ||
              !checkedSyarat ||
              !checkFirstName(inputRegister.firstName) ||
              !checkFirstName(inputRegister.lastName) ||
              inputRegister.firstName.length === 0 ||
              !/^(\+62|0)8[1-9][0-9]{6,16}$/.test(inputRegister.phone) ||
              inputRegister.lastName.length === 0 ||
              inputRegister.phone.length === 0 ||
              !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])[A-Za-z\d\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]{8,}$/.test(
                inputRegister.password
              ) ||
              inputRegister.password !== inputRegister.confirmPassword ||
              !/^(?=.*[a-z])(?=.*\d)[a-z\d]{6,}$/.test(
                inputRegister.userName
              ) ||
              isError ||
              loading
                ? true
                : false
            }
            value={"Kirim"}
            width={`300px`}
            height={`40px`}
            style={{ marginBottom: "10px" }}
          />
        </form>
      ) : (
        <div></div>
      )}
      <CustomModal
        isCustom={true}
        open={openActivation}
        onClose={() => setOpenActivation(false)}
      >
        <OTPRegister
          onClose={() => setOpenActivation(false)}
          activation={true}
          loading={(e) => setLoading(e)}
          invitationCode={invitationCode}
          activationURL={`/login?token=${token}&success=${successURI}&resourceID=${resourceID}&resourceName=${resourceName}&cancelURI=${cancelURI}&invitationCode=${invitationCode}&userType=${userType}&requestID=${requestID}&redirect_otp=true`}
          phone={inputRegister.phone}
        />
      </CustomModal>

      <CustomModal
        isCustom={true}
        open={timeoutModal}
        onClose={() => setTimeoutModal(false)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
            width: "100%",
            maxWidth: !matches ? "300px" : "100%",
          }}
        >
          <Box>
            <img src={ReloadIcon} style={{ width: "150px" }} />
          </Box>
          <Typography fontWeight={600} fontSize={20} textAlign="center">
            Muat ulang!
          </Typography>
          <Typography fontWeight={400} fontSize={16} textAlign="center">
            Anda terlalu lama tidak aktif, silahkan{" "}
            <span style={{ fontWeight: 600 }}>Muat Ulang</span> untuk kembali
            mengakses halaman.
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Button
              value={"Muat Ulang"}
              width={"100%"}
              height={"45px"}
              background={"#D81922"}
              color={"white"}
              onClick={() => reloadPage()}
            />
          </Box>
        </Box>
      </CustomModal>
      <CustomModal
        open={open}
        onClose={() => setOpen(false)}
        variant={status}
        text={message}
        title={title}
      />
      <CustomModal
        open={internetError}
        onClose={() => setInternetError(false)}
        variant={status}
        text={message}
      />
    </div>
  );
};

export default RegisterInvitation;
