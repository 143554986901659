import { ErrorOutline } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React, { useState } from "react";
import CustomNotification from "../atomic/Alert/CustomNotification";
import styles from "./OTPRegister.module.css";
import PropTypes from "prop-types";
import { postNoRestrict } from "../../services/Services";
import Button from "../atomic/Button/Button";
import { otpPhoneHelper } from "../../helper/otpPhoneHelper";
import OtpInput from "react-otp-input";

const OTPMigrations = ({ userID, phone, onSucces }) => {
  const [otpData, setOtpData] = useState("");
  const [resendOtp, setResendOtp] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [title, setTitle] = useState(null)

  const handleChange = (e) => {
    setOtpData(e);
  };

  const handleResendOtp = () => {
    postNoRestrict("/v3/auth/registration/activation/phone/resending", {
      user_id: userID,
      phone: otpPhoneHelper(phone),
    })
      .then((res) => {
        if (res.status === 200) {
          setResendOtp(resendOtp + 1);
        }
      })
      .catch((err) => {
        if (err) {
          setErrorMessage("Terjadi kesalahan mohon kontak developer Anda.");
          setOpenErrorAlert(true);
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let inputData = {};
    inputData = {
      user_id: parseInt(userID),
      phone: `${otpPhoneHelper(phone)}`,
      otp_code: otpData,
    };
    postNoRestrict("/v3/auth/registration/activation/phone/resource", inputData)
      .then((res) => {
        if (res.data.status === 1) {
          onSucces();
        } else {
          setErrorMessage("kode verifikasi salah, coba lagi");
          setOpenErrorAlert(true);
        }
      })
      .catch((err) => {
        if (err) {
          setErrorMessage("Terjadi kesalahan mohon kontak developer Anda.");
          setTitle('System Error!')
          setOpenErrorAlert(true);
        }
      });
  };
  return (
    <div className={styles.otpContainer}>
      <CustomNotification
        onClose={() => setOpenErrorAlert(false)}
        open={openErrorAlert}
        message={errorMessage}
        title={title}
      />
      <Typography
        variant="h5"
        component="h5"
        fontWeight={600}
        color={"#404040"}
        textAlign={"center"}
      >
        AKTIVASI NO TELEPON
      </Typography>
      {resendOtp === 3 ? (
        <div className={styles.alertOtp}>
          <ErrorOutline color="yellow" fontSize={"large"} />
          <Typography variant="body2" fontSize={14}>
            Silahkan hubungi 021-2222-0138 untuk bantuan
          </Typography>
        </div>
      ) : (
        ""
      )}

      <div
        style={{
          boxSizing: "border-box",
          width: "330px",
          textAlign: "center",
          fontSize: "14px",
        }}
      >
        Masukkan Kode OTP yang dikirimkan ke nomor Handphone Untuk Aktivasi
        GroChat Account
      </div>
      <div className={styles.inputOTPContainer}>
        <OtpInput
          onChange={handleChange}
          numInputs={6}
          separator={<span></span>}
          value={otpData}
          inputStyle={{
            outline: "none",
            border: "none",
            borderBottom: "1px solid red",
            margin: "20px 10px",
            width: "30px",
            height: "30px",
            fontSize: "16px",
          }}
        />
        {resendOtp < 3 ? (
          <>
            <Typography className={styles.resendOtpTimes} fontSize={12}>
              OTP ke - {`${resendOtp}`}
            </Typography>
            <Typography fontSize={14}>Belum terima kode ?</Typography>
            <Typography
              className={styles.kirimUlang}
              fontSize={16}
              onClick={handleResendOtp}
            >
              Kirim Ulang
            </Typography>
          </>
        ) : (
          ""
        )}

        <div style={{ marginBottom: "20px", marginTop: "10px" }}>
          <Button
            value={"Kirim"}
            onClick={handleSubmit}
            width={`200px`}
            disabled={otpData.length < 6}
            height={`40px`}
            style={{ marginBottom: "10px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default OTPMigrations;

OTPMigrations.propTypes = {
  userID: PropTypes.string,
  phone: PropTypes.string,
  onSucces: PropTypes.func,
};
