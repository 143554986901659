import { useTheme } from "@emotion/react";
import {
  Box,
  Dialog,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import SuccessIcon from "../../../assets/success.png";
import FailedIcon from "../../../assets/failed.png";
import "./CustomModal.module.css";
import PropTypes from "prop-types";

const CustomModal = ({ open, onClose, variant, text, children, isCustom, title = 'Tidak Berhasil!' }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      PaperProps={
        matches && {
          sx: {
            position: "absolute",
            bottom: -50,
            borderRadius: "30px",
            width: "100vw",
          },
        }
      }
    >
      <Box
        sx={{
          paddingBlock: matches ? "20px" : "30px",
          paddingInline: "40px",
          minWidth: "250px",
        }}
      >
        {matches && (
          <Box
            sx={{
              width: "75px",
              height: 5,
              backgroundColor: "#ddd",
              borderRadius: "30px",
              margin: "0px auto 20px auto",
            }}
            onClick={() => onClose()}
          ></Box>
        )}
        {!isCustom ? (
          <Box
            display={"flex"}
            flexDirection="column"
            alignItems={"center"}
            justifyContent="center"
            gap={"20px"}
          >
            <img
              src={variant === "success" ? SuccessIcon : FailedIcon}
              style={{ width: "150px" }}
            />
            <Typography fontSize={"23px"} fontWeight="600">
              {variant === "success" ? "Berhasil!" : title}
            </Typography>
            <Typography
              fontSize={"14px"}
              fontWeight="400"
              maxWidth={"250px"}
              textAlign="center"
            >
              {text}
            </Typography>
            {variant === "success" && (
              <Box>
                <Typography
                  fontSize={"14px"}
                  fontWeight="400"
                  maxWidth={"250px"}
                  textAlign="center"
                >
                  {moment().format("DD MMMM YYYY")}
                </Typography>
                <Typography
                  fontSize={"14px"}
                  fontWeight="400"
                  maxWidth={"250px"}
                  textAlign="center"
                >
                  {moment().format("HH:mm")}
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          <>{children}</>
        )}
      </Box>
    </Dialog>
  );
};

export default CustomModal;


CustomModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  variant: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node,
  isCustom: PropTypes.bool,
  title: PropTypes.string
};