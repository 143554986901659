import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { postNoRestrict } from "../../services/Services";
import Button from "../atomic/Button/Button";
import styles from "./ForgetPassword.module.css";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CustomNotification from "../atomic/Alert/CustomNotification";
import { clearForget } from "../../redux/action/forgetPassAction";

export default function ForgetPasswordForm({ onClose, onSuccess, onOpenOtp }) {
  // Redux State
  const dispatch = useDispatch();
  const forgetData = useSelector((state) => state.forgetPassData.data);

  // Local State
  const [typePassword, setTypePassword] = useState(false);
  const [typeConfirmPassword, setTypeConfirmPassword] = useState(false);
  const [inputData, setInputData] = useState({
    new_password: "",
    verify_new_password: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    status: false,
    message: "",
  });
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => {
    if (typePassword) {
      setTypePassword(false);
    } else {
      setTypePassword(true);
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowConfirmPassword = () => {
    if (typeConfirmPassword) {
      setTypeConfirmPassword(false);
    } else {
      setTypeConfirmPassword(true);
    }
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (e) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  const handleValidate = (value) => {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        value
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    if (handleValidate(inputData.new_password)) {
      if (inputData.new_password === inputData.verify_new_password) {
        let newData = {
          ...inputData,
          phone: forgetData.phone,
          forget_code: forgetData.forget_code.toString(),
        };
        postNoRestrict("/v3/auth/password/forget/change", newData)
          .then((res) => {
            setLoading(false);
            if (res.data.status === 1) {
              onSuccess();
              onClose();
              dispatch(clearForget());
            } else {
              setErrorMessage({
                ...errorMessage,
                message: res.data.note,
              });
              onOpenOtp();
              // setOpenErrorAlert(true);
            }
          })
          .catch((err) => {
            setLoading(false);
            if (
              err.response.data.data.nexsoft.payload.status.code ===
              "E-4-AUT-SRV-006"
            ) {
              setOpenErrorAlert(true);
              onOpenOtp();
              onClose();
            } else if (
              err.response.data.data.nexsoft.payload.status.code ===
              "E-4-AUT-SRV-011"
            ) {
              setErrorMessage({
                ...errorMessage,
                message: err.response.data.data.nexsoft.payload.status.code,
              });
              setOpenErrorAlert(true);
              onClose();
            } else {
              setErrorMessage({
                ...errorMessage,
                message: "Terjadi kesalahan mohon kontak developer anda.",
                title: "System Error!" 
              });
              setOpenErrorAlert(true);
            }
          });
      } else {
        setErrorMessage({
          ...errorMessage,
          passwordRegex: false,
          passwordConfirm: true,
          status: true,
        });
      }
      setLoading(false);
    } else {
      setErrorMessage({
        ...errorMessage,
        passwordRegex: true,
        passwordConfirm: false,
        status: true,
      });
      setLoading(false);
    }
  };
  return (
    <div className={styles.formForgetContainer}>
      <CustomNotification
        onClose={() => setOpenErrorAlert(false)}
        open={openErrorAlert}
        message={errorMessage.message}
      />
      <Typography
        className={styles.lupaKataSandi}
        variant="body2"
        fontSize={24}
        fontWeight={600}
        color={"#404040"}
      >
        LUPA KATA SANDI
      </Typography>

      <form onSubmit={handleSubmitForm}>
        <div className={styles.passwordContainer}>
          <FormControl
            error={errorMessage.status ? errorMessage.passwordRegex : false}
            sx={{ width: "90%" }}
            variant="standard"
          >
            <InputLabel htmlFor="standard-adornment-password" shrink>
              Kata Sandi Baru
            </InputLabel>
            <Input
              id="standard-adornment-password"
              type={typePassword ? "text" : "password"}
              onChange={handleChange}
              name={"new_password"}
              placeholder={"Masukan kata sandi"}
              aria-describedby="component-error-text"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {typePassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Box
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
              sx={{ width: "100%", marginBottom: "10px" }}
            >
              {inputData.new_password.length !== 0 &&
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])[A-Za-z\d\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]{8,}$/.test(
                  inputData.new_password
                ) && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      fontStyle: "italic",
                      textAlign: "left",
                    }}
                  >
                    *Kata sandi harus terdiri dari 8 karakter, 1 huruf kapital,
                    1 angka, 1 karakter special
                  </span>
                )}
            </Box>
          </FormControl>
        </div>
        <div className={styles.passwordContainer}>
          <FormControl
            error={errorMessage.status ? errorMessage.passwordConfirm : false}
            sx={{ width: "90%", margin: "20px 0px" }}
            variant="standard"
          >
            <InputLabel htmlFor="standard-adornment-password-confirm" shrink>
              Konfirmasi Kata Sandi Baru
            </InputLabel>
            <Input
              id="standard-adornment-password-confirm"
              type={typeConfirmPassword ? "text" : "password"}
              onChange={handleChange}
              name={"verify_new_password"}
              placeholder={"Masukan kata sandi"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password confirm visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                  >
                    {typeConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Box
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
              sx={{ width: "100%", marginBottom: "10px" }}
            >
              {inputData.verify_new_password !== inputData.new_password && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    fontStyle: "italic",
                    textAlign: "left",
                  }}
                >
                  *Password tidak sama
                </div>
              )}
            </Box>
          </FormControl>
        </div>
        <div className={styles.formButton}>
          <Button
            value={"Kirim"}
            width={`300px`}
            height={`50px`}
            disabled={loading}
          />
        </div>
      </form>
    </div>
  );
}

ForgetPasswordForm.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onOpenOtp: PropTypes.func,
};
